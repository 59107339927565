// ForJWT Auth
import { getUserFromJwtAuth } from "./helper/AuthHelper";
import {
  useFilter,
  useJWTAuth,
  useJWTAuthActions,
  useTenant,
  useTenantActions
} from "../services/auth/jwt-auth/JWTAuthProvider";



export const useTenantParams = () => {
  const { tenantParams } = useTenant();
  return tenantParams;
};

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading, menu, scheduleId } = useJWTAuth();

  return {
    isLoading,
    scheduleId: scheduleId,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
    menu: menu? menu: [],
    // tenantParams:null
  };
};

export const useFilterParam = () => {
  const {filter} = useFilter()

  return filter;
}
export const useAuthMethod = () => {
  const { signInUser, signUpUser, logout } = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
  };
};