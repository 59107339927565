import React from "react";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import { alpha, Box } from "@mui/material";
import { Fonts } from "../../../../shared/constants/AppEnums";
import AppCard from "@crema/core/AppCard";
import { Height } from "@mui/icons-material";


interface IncomeProps {
    IncomeData?: IncomeData;
}
export interface IncomeData{
    IncomeTotal: number;
    IncomeLastMonth: number;
    IncomeLast12Month: number;
}


const Income: React.FC<IncomeProps> = ({ IncomeData }) => {
  return (
    <Box>
      <AppCard sxStyle={{ position: "relative", height: 137, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              component="h2"
              sx={{
                width: "100%",
                color: "text.primary",
                fontSize: 14,

                mb: { xs: 4, sm: 4, xl: 2 },
                fontWeight: Fonts.BOLD,
              }}
            >
                <IntlMessages id={"dashboard.income"} /> 
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box  sx={{ mb: { xs: 4, sm: 4, xl: 2 },  marginRight: 3,}}>
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 13,
                    color: "primary.main",
                  }}
                >
                  R$ {IncomeData?.IncomeTotal ? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2}).format(Number(IncomeData?.IncomeTotal)) : "-"} 
                  
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 12,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id={"dashboard.incomeGross"} /> 

                </Box>
              </Box>

              <Box sx={{ mb: { xs: 4, sm: 4, xl: 2 }} }>
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 13,
                    color: "primary.main",
                  }}
                >
                    
                  R$ {IncomeData?.IncomeLast12Month ? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2}).format(Number(IncomeData?.IncomeLast12Month/12)) : "-"} 
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 12,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id={"dashboard.incomeAverage"} /> 
                </Box>
              </Box>
            </Box>

            <Box
            sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            }}
            >
              <Box sx={{ mb: { xs: 4, sm: 4, xl: 2 }} }>
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 13,
                    color: "primary.main",
                  }}
                >
                  R$ {IncomeData?.IncomeLastMonth ? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2}).format(Number(IncomeData?.IncomeLastMonth)) : "-"} 
                  
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 12,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id={"dashboard.incomeLastMonth"} /> 

                </Box>
              </Box>
            </Box>
          </Box>

        </Box>

       
      </AppCard>
    </Box>
  );
};

export default Income;
