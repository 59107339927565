import { AppCard } from '@crema';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { useAuthUser } from '@crema/utility/AuthHooks';
import IntlMessages from '@crema/utility/IntlMessages';
import { Box, useMediaQuery } from '@mui/material';
import React, { PureComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AppState } from 'redux/store';
import { Fonts } from 'shared/constants/AppEnums';


interface NewAccountsLastYearTreated {
    name: string,
    pv: number
}

interface NewAccountsLastYear {
    Month: string,
    Quantity: number
}



const Chart: React.FC = () => {
    const { filtro, vision } = useSelector<AppState, AppState["filter"]>(({ filter }) => filter);
    const {user} = useAuthUser();
    const filter = filtro;
    const [newAccountsLastYearTreated, setNewAccountsLastYearTreated] = React.useState<NewAccountsLastYearTreated[]>([]);
    
    var id = filter !== ""? filter: user?.userId
    useEffect(() => {
        let isMounted = true;

        if(vision !== ''){
            jwtAxios.get("/NewAccountsLastYearPanel/GetNewAccountsLastYears/" + id + "/" + vision).then((res) => {
                if (isMounted) {
                    var formattedNewAccountsLastYear: NewAccountsLastYearTreated[] = [];
                    res.data.map((item: NewAccountsLastYear) => {  
                        formattedNewAccountsLastYear.push({ name: item.Month, pv: item.Quantity });
                    })
                    setNewAccountsLastYearTreated(formattedNewAccountsLastYear);
                }
            })
        }
         // Função de limpeza
        return () => {
            isMounted = false;
        };
    }, [filter,vision]);

    const matches = useMediaQuery('(max-width:1400px)');
    const { messages } = useIntl();
    // const data = [
    //     {
    //         name: messages["chart.jan"],
    //         pv: 50
    //     },


    // ];
   
    return (
        
        <Box>
            <AppCard sxStyle={{ height: 1 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        textAlign: "start",
                    }}
                >
                    <Box>


                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "start",
                                marginLeft: 3

                            }}
                        >
                            <Box
                                sx={{
                                    mt: { xl: 3 },

                                }}
                            >
                                <Box
                                    component="h2"
                                    sx={{
                                        color: "text.primary",
                                        fontSize: 16,
                                        //
                                        mb: { xs: 4, sm: 4, xl: 3 },
                                        fontWeight: Fonts.BOLD,
                                    }}
                                >
                                    <IntlMessages id="chart.title" />
                                </Box>
                            </Box>

                            <BarChart
                                width={matches? 450: 600}
                                height={200}
                                data={newAccountsLastYearTreated}
                                margin={{
                                    top: 20,
                                    right: matches? 15: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                barCategoryGap={10}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="pv" name="Contas abertas" stackId="a" fill="#0D4B7C" />
                            </BarChart>

                        </Box>
                    </Box>
                </Box>
            </AppCard >
        </Box >
    );
}

export default Chart;
