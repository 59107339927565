import {configs} from './params';
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import AppList from "@crema/core/AppList";
import UploadModern from "pages/thirdParty/reactDropzone/components/UploadModern";
import FileRow from "pages/thirdParty/reactDropzone/components/FileRow";
import jwtAxios from '@crema/services/auth/jwt-auth';
import StyledDropzone from '../../thirdParty/reactDropzone'
import { AppAnimate, AppCard, AppGridContainer, AppInfoView } from '@crema';
import { Button, Grid, Box, MenuItem, TextField, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography, Popover, Checkbox } from "@mui/material";
import IntlMessages from "@crema/utility/IntlMessages";
import { useIntl } from 'react-intl';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Fonts } from 'shared/constants/AppEnums';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface IInvoice{
    InvoiceId:string,
    UploadId: string,
    InvoiceNumber: string,
    BilledDate: string,
    InvoiceBilled:boolean,
    BillingDate: string,
  }
  interface IReportb2b{
    reportId: any,
    functionClose: any,
    reloadFunction:any,
  }

const ReportB2B = (reportB2B:IReportb2b) => {

    const initialInvoice: IInvoice = {
        InvoiceId: '',
        UploadId: '',
        InvoiceNumber: '',
        BilledDate: '',
        InvoiceBilled: false,
        BillingDate: '',
      };
   
    const dropzoneText = "dragthefilehere";
    const data = configs.Fields;
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { messages } = useIntl();
    const [selectedType, setSelectedType] = useState("");
    const [selectedMonth, setSelectedMotnh] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceName, setInvoiceName] = useState("");
    const [billedDate, setBilledDate] = useState<Date | null>(null);
    const [billingDate, setBillingDate] = useState<Date | null>(null);
    const [invoiceBilled, setInvoiceBilled] = useState(false);
    const [referenceMonth, setReferenceMonth] = useState("");
    const [isUpdate, setIsUpdate] = useState<Boolean | null>(null);
    const [invoice, setInvoice] = useState<IInvoice>(initialInvoice);
    const uploadId = reportB2B.reportId;

    const handleInvoiceNumberChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInvoiceNumber(event.target.value);
        setInvoice({
            ...invoice, // Mantenha as outras propriedades inalteradas                   
            InvoiceNumber: event.target.value,
          });
    }

    const handleBilledDateChange = (newValue: Date | null) => {
        setBilledDate(newValue);
        setInvoice({
            ...invoice, // Mantenha as outras propriedades inalteradas  
            BilledDate: dateFormatValid(newValue),
          });
    }

    const handleBillingDateChange = (newValue: Date | null) => {
        setBillingDate(newValue);
        setInvoice({
            ...invoice, // Mantenha as outras propriedades inalteradas                               
            BillingDate: dateFormatValid(newValue),
          });
       
    }

    const handleInvoiceBilledChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInvoiceBilled(event.target.checked);
        setInvoice({
            ...invoice, // Mantenha as outras propriedades inalteradas                              
            InvoiceBilled: event.target.checked,
          });
    }


    const dropzone = useDropzone({
        accept: '.pdf',  
        maxFiles:1,    
        multiple:false,
    });

    useEffect(() => {
        setUploadedFiles(dropzone.acceptedFiles);
    }, [dropzone.acceptedFiles]);

    useEffect(() => {
        if (reportB2B.reportId != null) {

            jwtAxios.get("invoice/GetInvoiceById/" + reportB2B.reportId).then((response) => {
                if(response.status === 200){
                    setInvoice(response.data)
                    setInvoiceNumber(response.data.InvoiceNumber);
                    setBilledDate(response.data.BilledDate);
                    setInvoiceBilled(response.data.InvoiceBilled);
                    setInvoiceName(response.data.InvoiceName);

                    const [year, month, day] = response.data.ReferenceMonth.toString().substring(0,10).split('-');
                    const monthRef = `${month}/${year}`;

                    setReferenceMonth(monthRef);
                    setIsUpdate(true);
                }
                else {
                    setIsUpdate(false);
                }
            })
        }
    }, []);

    const onDeleteUploadFile = (file: any) => {
        dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1);
        setUploadedFiles([...dropzone.acceptedFiles]);
    };

    const handleClosePopover =() =>{
        reportB2B.functionClose();
        reportB2B.reloadFunction();
    };

    const formatDate =(date:Date | null) =>{
        const year = date?.getFullYear();
            const month = (date?.getMonth()! + 1).toString().padStart(2, '0');
            const day = date?.getDate().toString().padStart(2, '0');
    
            return `${year}-${month}-${day}`;
    };
    
    function dateFormatValid(dateString) {
        // A expressão regular para validar o formato YYYY-MM-DDTHH:mm:ss
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
      
        // Teste se a string corresponde à expressão regular

        if(!regex.test(dateString)){
            dateString = formatDate(dateString)
        }

        return dateString;
      }

    const handleFileUpload = async () => {
        //handleClosePopover();
        if (!uploadedFiles) {
      
        return;
        }
      
        const formData = new FormData();
        formData.append('file', uploadedFiles[0]);
        
       
        try {
            dispatch(fetchStart());

            
            //const year = billedDate?.getFullYear();
            //const month = (billedDate?.getMonth()! + 1).toString().padStart(2, '0');
            //const day = billedDate?.getDate().toString().padStart(2, '0');
    
            //const formatBilledDate = `${year}-${month}-${day}`;
            

            const formatBilledDate = formatDate(billedDate);
            const formatBillingDate = formatDate(billingDate);
            
            
           

            if(invoiceNumber!== "" && formatBilledDate !== null && reportB2B.reportId !== null && reportB2B.reportId !== undefined && reportB2B.reportId !== "" ){
                await jwtAxios.post(`uploads/uploadfileInvoice/${invoiceNumber}/${formatBilledDate}/${invoiceBilled}/${reportB2B.reportId}/${formatBillingDate}`, formData).then((res) => {               
                    
                    const [code, description] = res.data.toString().split(':');
                    if(code.trim() === '3'){                    
                        dispatch(fetchSuccess());   
                        dispatch(showMessage(description)) 
                        setTimeout(() => {
                            handleClosePopover();
                            navigate('/admin/reportb2b/list');
                        }, 1800); 
                    }                
                    else{
                        
                        dispatch(fetchError(description));                       
                        setTimeout(() => {
                            handleClosePopover();
                            if(code.trim() !== '97'){
                                onDeleteUploadFile(uploadedFiles[0])
                            }
                        }, 2000); 
                    }
                });
            }
            else{
                        
                dispatch(fetchError("Preencha os dados da Nota Fiscal!"));                       
                setTimeout(() => {
                    handleClosePopover();
                        onDeleteUploadFile(uploadedFiles[0]);
                   
                }, 2000); 
            }
            
        
        } catch (error) {
       
        dispatch(fetchError('Error uploading file:'+ error));
        setTimeout(() => {
            navigate('/admin/reportb2b/list')
        }, 1800);  
        }

   
    };

    const handleUpdate = async () => {
        try {
            dispatch(fetchStart());
            
            const formatBilledDate = dateFormatValid(billedDate);
            const formatBillingDate = dateFormatValid(billingDate);
            

            if(invoiceNumber!== "" && formatBilledDate !== null && reportB2B.reportId !== null && reportB2B.reportId !== undefined && reportB2B.reportId !== "" ){
                 
                const invoiceUpdate:IInvoice = {
                    InvoiceId: invoice.InvoiceId,
                    UploadId: uploadId,
                    InvoiceNumber: invoiceNumber,
                    BilledDate: formatBilledDate,
                    InvoiceBilled:invoiceBilled,
                    BillingDate: formatBillingDate,
                }
                
               
                await jwtAxios.post(`invoice/update`,invoice ).then((res) => {               
                    
                    const [code, description] = res.data.toString().split(':');
                    if(code.trim() === '3'){                    
                        dispatch(fetchSuccess());   
                        dispatch(showMessage(description)) 
                        setTimeout(() => {
                            handleClosePopover();
                            navigate('/admin/reportb2b/list');
                        }, 1800); 
                    }                
                    else{
                        
                        dispatch(fetchError(description));                       
                        setTimeout(() => {
                            handleClosePopover();
                            if(code.trim() !== '97'){
                                onDeleteUploadFile(uploadedFiles[0])
                            }
                        }, 2000); 
                    }
                });
            }
            else{
                        
                dispatch(fetchError("Preencha os dados da Nota Fiscal!"));                       
                setTimeout(() => {
                    handleClosePopover();
                        onDeleteUploadFile(uploadedFiles[0]);
                   
                }, 2000); 
            }
            
        
        } catch (error) {
       
        dispatch(fetchError('Error Update Invoice:'+ error));
        setTimeout(() => {
            navigate('/admin/reportb2b/list')
        }, 1800);  
        }

   
    };
    
    
    const handleTypeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSelectedType(event.target.value);
    }

    const handleMonthChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        
        setSelectedMotnh(event.target.value);
    }


    const handleCancel = () => {
        navigate(-1);
      };

  
  return (
    <>
    
        <AppAnimate animation="transition.slideUpIn" delay={200}>
        <AppCard>
            <Typography sx={{ height: 420, width: 700, mb: "20px", backgroundColor: "white", border: 1, borderColor: "#cccccc" }}>
                <Grid item xs={12}>
                    <Typography sx={{ p: 1, backgroundColor: "#0D4B7C", color: "white" }}>
                        <IntlMessages id="invoiceName" />   <span>&nbsp;</span>
                        <IntlMessages id="referenceMonth" />  : {referenceMonth}
                    </Typography>
                </Grid>
                {isUpdate !== null &&
                <Grid container spacing={4} rowSpacing={5} style={{width: "100%",marginTop: "5px", marginLeft:"1px", marginRight:"1px", paddingRight:"13px"}}>
                    <Grid item xs={4} sm={4} md={4}>               
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                value={invoiceNumber}
                                multiline
                                rows={1}
                                onChange={handleInvoiceNumberChange}
                                variant="outlined"
                                name="invoiceNumber"
                                fullWidth
                                placeholder={''}
                                label={<IntlMessages id={`invoiceNumber`} />}
                                />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl sx={{ width: "100%" }}>
                                <DesktopDatePicker
                                    //disablePast
                                    label={<IntlMessages id={`billedDate`} />}
                                    inputFormat="dd/MM/yyyy"
                                    value={billedDate === undefined ? null : billedDate}
                                    onChange={handleBilledDateChange}
                                    //minDate={new Date()}
                                    renderInput={(params) => <TextField {...params}
                                    name="billedDate"
                                    style={{
                                        width: "100%",
                                        fontWeight: Fonts.LIGHT,
                                        marginBottom: 3,
                                    }} />}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl sx={{ width: "100%" }}>
                                <DesktopDatePicker
                                    //disablePast
                                    label={<IntlMessages id={`billingDate`} />}
                                    inputFormat="dd/MM/yyyy"
                                    value={billingDate === undefined ? null : billingDate}
                                    onChange={handleBillingDateChange}
                                    //minDate={new Date()}
                                    renderInput={(params) => <TextField {...params}
                                    name="billingDate"
                                    style={{
                                        width: "100%",
                                        fontWeight: Fonts.LIGHT,
                                        marginBottom: 3,
                                    }} />}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <FormControl sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <Box
                                sx={{
                                fontSize:14,
                                color: "text.secondary",
                                alignItems:"center",
                                }}
                            >
                                <IntlMessages id={`invoiceBilled`} />
                            </Box>
                            <Box
                                sx={{
                                alignItems:"center",
                                }}
                            >
                                <Checkbox
                                    onChange={handleInvoiceBilledChange}
                                    checked={invoiceBilled}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                        color: "#0D4B7C",
                                        '&.Mui-checked': {
                                        color: "#0D4B7C",
                                        },
                                    }}
                                    />
                            </Box>
                        </FormControl>
                    </Grid>
                    {isUpdate &&
                    <Grid item xs={12} sm={12} md={12}>               
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                value={invoiceName}
                                multiline
                                rows={1}
                               disabled={true}
                                variant="outlined"
                                name="invoiceName"
                                fullWidth
                                placeholder={''}
                                label={<IntlMessages id={`languages.filename`} />}
                                />
                        </FormControl>
                    </Grid>
                    }
                     {!isUpdate &&
                    <Grid item xs={12} sm={12} >
                        <Box sx={{ position: "relative" }}>
                            {/*<StyledDropzone/>*/}
                            <UploadModern
                                uploadText={intl.formatMessage({ id: 'common.dragthefilehere' })}
                                dropzone={dropzone}        
                            />
                            <aside>
                                <AppList
                                data={uploadedFiles}
                                renderRow={(file: any, index: number) => (
                                    <FileRow
                                    key={index + file.path}
                                    file={file}
                                    onDeleteUploadFile={onDeleteUploadFile}
                                    
                                    />
                                )}
                                />                            
                            </aside>                    
                        </Box>
                    </Grid>
                    }
                    <Grid item xs={12} md={12} xl={12}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                sx={{
                                    position: "relative",
                                    minWidth: 100,
                                }}
                                color="primary"
                                variant="contained"
                                //type="submit"
                                //disabled={isSubmitting}
                                onClick={isUpdate ? handleUpdate :handleFileUpload}
                                //disabled={isUpdate === true ? true : false}
                            >
                                <IntlMessages id={ isUpdate ? "update" :"upload"} />
                            </Button>
                            {/**
                            <Button
                                sx={{
                                    position: "relative",
                                    minWidth: 100,
                                    ml: 2.5,
                                }}
                                color="primary"
                                variant="outlined"
                                type="reset"
                                onClick={handleCancel}
                            >
                                <IntlMessages id="common.cancel" />
                            </Button>                                                
                             */}
                        </Box>                                            
                    </Grid>
                </Grid>   
                }  
            </Typography>      
        </AppCard>
    </AppAnimate>
    <AppInfoView/>
    </>

  );

};

export default ReportB2B;

