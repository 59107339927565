import React, { useMemo } from "react";
import { Icon, ListItemText } from "@mui/material";
import clsx from "clsx";
import AppBadge from "@crema/core/AppBadge";
import AppNavLink from "@crema/core/AppNavLink";
import Box from "@mui/material/Box";
import IntlMessages from "../../../../../utility/IntlMessages";
import { checkPermission } from "../../../../../utility/helper/RouteHelper";
import { useAuthUser } from "../../../../../utility/AuthHooks";
import VerticalNavItem from "./VerticalNavItem";
import { RouterConfigData } from "../../../../../../pages/routesConfig";
import * as FontAwesome from "react-icons/fa";

interface VerticalItemProps {
  item: RouterConfigData;
  level: number;
}

const VerticalItem: React.FC<VerticalItemProps> = ({ level, item }) => {  
  const { user } = useAuthUser();
  const hasPermission = useMemo(
    () => checkPermission(item.permittedRole, user?.role),
    [item.permittedRole, user?.role]
  );
  if (!hasPermission) {
    return null;
  }
  const IconMenu = props => {    
    const { iconName, size, color, classes } = props;
    const icon = React.createElement(FontAwesome[iconName]);
    return  <div style={{ fontSize: size, color: color }} className={"nav-item-text"}>{icon}</div>;
  }

  return (
    <VerticalNavItem
      level={level}
      button
      component={AppNavLink}
      to={item.url}
      activeClassName="active"
      exact={item.exact}
      style={{ marginLeft: -20 }}
    >
      {item.icon && (
        <Box component="span" style={{ marginRight: 8 }}>
          <IconMenu iconName={item.icon} size={14}  color={item.color}  style={{ float: 'left' }}/>
        </Box>
      )}
      <ListItemText
        className="nav-item-content"
        primary={<IntlMessages id={item.messageId} />}
        classes={{ primary: "nav-item-text" }}
      />
      {item.count && (
        <Box sx={{ mr: 3.5 }} className="menu-badge">
          <AppBadge count={item.count} color={item.color} />
        </Box>
      )}
    </VerticalNavItem>
  );
};

export default React.memo(VerticalItem);
