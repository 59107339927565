import React from "react";
import List from "@mui/material/List";

import RoutesConfig, {
  RouterConfigData,
} from "../../../../../pages/routesConfig"; 
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { Box, Divider, Paper } from "@mui/material";

const VerticalNav = () => {
  const {menu} = useAuthUser();
  
  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component="div"
    >
      {menu instanceof Array && menu.map((item: RouterConfigData, key) => (
        <React.Fragment key={key}>
          {item.type === "group" && 
          <NavVerticalGroup item={item} level={0} />}
          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} />            
          )}
          {item.type === "item" && 
            <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
     <Divider />
     
     
      {/*
      <Box
        sx={{
          mt: 1,
          display: { xs: "none", md: "block" },
          "& svg": { 
            height: { xs: 25, sm: 30 },
          },
        }}
      >
        <img
          style={{
            objectFit: "cover",
            marginRight: 5,
            width: "150px",
           
          }}
          src="/assets/logos/clickme_logo.png"
          alt="clickme-logo"
        />
      </Box>
        */}
   
    
    </List>
  );
}; 

export default VerticalNav;
