import AppAnimate from '@crema/core/AppAnimate';
import AppCard from '@crema/core/AppCard';
import { Box, FormControl, Grid, InputLabel, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NotificationsPainel, { ActivesPanelData, ContactsPanelData, ContributionsAndWithdrawalsPanelData, CustomersRangePanelData, NotificationsPanelData, WalletsPanelData, WelcomeCardData } from './NotificationsPanel';
import cryptoData, { contactPainelItemData, studentRankings, welcomeCardData, meetingsPainelData, accountData } from "../mock";
import AppGridContainer from '@crema/core/AppGridContainer';
import TotalBalance from './TotalBalance';
import Wallets from './Wallets';
import Actives from './Actives';

import Coins from './Coins';
import BuySell from './BuySell';
import Bitcoin from './Bitcoin';
import BtcVolumeCurrency from './BtcVolumeCurrency';
import PopularCoins from './PopularCoins';
import LatestNews from './LatestNews';
import CryptoMarketActivity from './CryptoMarketActivity';
import ContactsMade from './ContactsMade';
import ContactsNow from './Contacts';
import Meetings from './Meetings';
import CurrentAccount from './CurrentAccount';
import ContactsPanel from './Contacts';
import Chart from './chart';
import { AppGrid, AppLoader } from '@crema';
import Contribution from './Contribution';
import Custtomerrange from './Customerrange';
import { useIntl } from "react-intl";
import { Fonts } from 'shared/constants/AppEnums';
import { useAuthUser } from "@crema/utility/AuthHooks";
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import Income, { IncomeData } from './Income';
import IntlMessages from '@crema/utility/IntlMessages';
import { SelectChangeEvent, Select } from '@mui/material';
import ManageClientsDashboard from '../manageClientsDashboard';

type EvaDashboardProps = {
    filter: string;
    updateFilter: (newFilter: string) => void;
}
const EvaDashboard: React.FC = () => {
    const { filtro, vision } = useSelector<AppState, AppState["filter"]>(({ filter }) => filter);
    const filter = filtro;
    const { messages } = useIntl();
    const { user } = useAuthUser();
    const [notificationsData, setNotificationsData] = useState<WelcomeCardData[]>([]);
    const [contactsPanelData, setContactsPanelData] = useState<ContactsPanelData>({
        ContactPanelId: "",
        ContactPanelTypesId: "",
        UserId: "",
        Received: "",
        Incoming: "",
        Now: ""
    });

    const [panel, setPanel] = useState<string>("main");
    const [activesPanelData, setActivesPanelData] = useState<ActivesPanelData[]>([]);
    const [contributionsAndWithdrawalsPanelData, setContributionsAndWithdrawalsPanelData] = useState<ContributionsAndWithdrawalsPanelData[]>([]);
    const [notificationsPanelData, setNotificationsPanelData] = useState<NotificationsPanelData | undefined>();
    const [customersRangePanelData, setCustomersRangePanelData] = useState<CustomersRangePanelData[]>([]);
    const [walletsPanelData, setWalletsPanelData] = useState<WalletsPanelData | undefined>();
    const [newActivesPanelData, setNewActivesPanelData] = useState<ActivesPanelData[]>([]);
    const [newContributionsAndWithdrawalsPanelData, setNewContributionsAndWithdrawalsPanelData] = useState<ContributionsAndWithdrawalsPanelData[]>([]);
    const [newNotificationsPanelData, setNewNotificationsPanelData] = useState<NotificationsPanelData | undefined>();
    const [newCustomersRangePanelData, setNewCustomersRangePanelData] = useState<CustomersRangePanelData[]>([]);
    const [newWalletsPanelData, setNewWalletsPanelData] = useState<WalletsPanelData | undefined>();
    const [isLoadingPanelData, setIsLoadingPanelData] = useState(true);
    const [incomeData, setIncomeData] = useState<IncomeData>({
        IncomeTotal: 0,
        IncomeLastMonth: 0,
        IncomeLast12Month: 0,
    });
    const dispatch = useDispatch();

    const handlePanelViewChange = (event: SelectChangeEvent) => {
        setPanel(event.target.value)
        dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader: true, filterDataTable:'',filterCustom:'' });
    }

    useEffect(() => {
        if (panel === "main") {
            dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader: true, filterDataTable:'',filterCustom:'' });
            setIsLoadingPanelData(true);



            const suitability: WelcomeCardData =
            {
                id: 3,
                type: "Suitability",
                counts: 0,
                icon: "AiOutlineWarning",
            };
            setNotificationsData(current => [suitability, ...current]);
            // });

            jwtAxios.post("/investors/GetAllBirthdaysOfTheDay", { isActive: true, userId: filter !== "" ? filter : user?.userId }).then((res) => {
                let birthDayOfDay: WelcomeCardData =
                {
                    id: 1,
                    type: "birthday.day",
                    counts: res.data ? res.data : 0,
                    icon: "HiOutlineMailOpen"
                };
                setNotificationsData(current => [birthDayOfDay, ...current]);
            });

            jwtAxios.post("/investors/GetAllBirthdaysOfTheMonth", { isActive: true, userId: filter !== "" ? filter : user?.userId }).then((res) => {

                let birthDayOfMonth: WelcomeCardData =
                {
                    id: 2,
                    type: "birthday.month",
                    counts: res.data ? res.data : 0,
                    icon: "CgFileDocument",
                };
                setNotificationsData(current => [birthDayOfMonth, ...current]);
            });



            jwtAxios.post("/notificationsPanel/getbyfilter", { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision }).then((res) => {

                if (res.status !== 200) {
                    setNotificationsPanelData(undefined);
                }
                else {
                    setNotificationsPanelData(res.data.List[0]);
                }
            })
            // jwtAxios.post("/contactsPanel/getbyfilter", { ParamSearch: { isActive: true } }).then((res) => {

            //     setContactsPanelData(res.data.List)
            // })
            jwtAxios.post("/activesPanel/getbyfilter", { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision }).then((res) => {
                if (res.status !== 200) {
                    setActivesPanelData([])
                }
                else {
                    setActivesPanelData(res.data.List);
                }
            }).finally(() => setIsLoadingPanelData(false))
            jwtAxios.post("/ContributionsAndWithdrawalsPanel/getbyfilter", { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision }).then((res) => {
                if (res.status !== 200) {
                    setContributionsAndWithdrawalsPanelData([])
                }
                else {
                    setContributionsAndWithdrawalsPanelData(res.data.List);
                }
            })
            jwtAxios.post("/customersRangePanel/getbyfilter", { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision }).then((res) => {
                if (res.status !== 200) {
                    setCustomersRangePanelData([])
                }

                else {
                    setCustomersRangePanelData(res.data.List);
                }
            })
            jwtAxios.post("/walletsPanel/getbyfilter", { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision }).then((res) => {
                if (res.status !== 200) {
                    setWalletsPanelData(undefined);
                }
                else {
                    setWalletsPanelData(res.data.List[0]);
                }
            })
            jwtAxios.post("/incomePanel/getbyfilter", { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision }).then((res) => {
                if (res.status == 200) {
                    setIncomeData(res.data.List[0]);
                }

            })
        }
    }, [filter, vision])

    return (
        isLoadingPanelData ? <AppLoader /> :
            <>
                <FormControl style={{ margin: "20px 0", width: "200px"}}>
                    <InputLabel id="demo-simple-select-label"><IntlMessages id={"panel"} /></InputLabel>
                    <Select
                        variant='outlined'
                        onChange={handlePanelViewChange}
                        value={panel}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                    >
                        <MenuItem value="main"><IntlMessages id={"main"} /></MenuItem>
                        <MenuItem value="next"><IntlMessages id={"next"} /></MenuItem>
                    </Select>
                </FormControl >

                {panel === "main" ? 
                
                    <AppGridContainer>
                        <Grid item xs={12} lg={6} md={8} xl={5} sm={12} style={{ paddingBottom: 0, marginBottom: -2 }}>
                            <NotificationsPainel data={notificationsPanelData} />
                        </Grid>
                        <Grid item xs={12} md={8} lg={6} xl={5} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                            <Grid item xs={12} md={4} lg={8} xl={7} sm={12} style={{ paddingBottom: 0, }}>
                                <ContactsPanel contactsPanelData={contactsPanelData} />
                            </Grid>
                            <Grid item xs={12} md={5} lg={4} xl={5} sm={12} style={{ paddingBottom: 0, margin: "0 10px" }}>
                                <AppGridContainer>
                                    <Grid item xs={12} md={12} lg={12} xl={12} sm={12} style={{ paddingBottom: 0 }}>
                                        <Meetings totalBalanceData={meetingsPainelData} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} sm={12} style={{ paddingBottom: 0 }}>
                                        {/* <CurrentAccount CurranteAccountData={accountData} /> */}
                                        <Income IncomeData={incomeData} />

                                    </Grid>
                                </AppGridContainer>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                            <AppGridContainer>
                                <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
                                    <Wallets walletsPanelData={walletsPanelData} currentAccountData={activesPanelData.find(item => item.NameActiveType == "Conta Corrente")} />
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
                                    <Chart />
                                </Grid>
                            </AppGridContainer>
                        </Grid>
                        <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                            <Actives activesPanelData={activesPanelData} />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                            <Custtomerrange customerRangeData={customersRangePanelData} />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6} xl={5} sm={12}>
                            <Contribution contributionsAndWithdrawalsPanelData={contributionsAndWithdrawalsPanelData} />
                        </Grid>

                    </AppGridContainer>
                    : <ManageClientsDashboard />}
            </>
    )
};
export default EvaDashboard;