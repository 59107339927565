import FormList from '../list/base';
import {configs} from './params';

const InvestorsList = () => {
  return (
        <FormList configs={configs} />
  );
};

export default InvestorsList;
