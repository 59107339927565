import { NavStyle } from "shared/constants/AppEnums";
import HeaderUserLayout from "./UserHeader";



const Layouts: any = {

  [NavStyle.HEADER_USER]: HeaderUserLayout,


};
export default Layouts;
