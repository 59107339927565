import React, { useEffect, useState } from "react";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Box, Icon, IconButton, ListItem, Typography } from "@mui/material";
import { Fonts } from "../../../shared/constants/AppEnums";
import * as FontAwesome from "react-icons/fa";
import jwtAxios from "@crema/services/auth/jwt-auth";
import ClearIcon from '@mui/icons-material/Clear';
import { NotificationData } from "./AppNotificationContent";
import { useDispatch } from "react-redux";
import { fetchStart, fetchSuccess, showMessage } from "redux/actions";
import AppInfoView from "../AppInfoView";


interface NotificationItemProps {
  item: {
    NotificationsId: string;
    NotificationsTypeId: string;
    Title: string;
    Description: string;
    IsRead: boolean
  },
  onNotificationRead: (objeto: any) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({ item, onNotificationRead }) => {
  const [icon, setIcon] = useState('');
  const dispatch = useDispatch();
  const Icon = props => {
    const { iconName, size, color } = props;
    const render = React.createElement(FontAwesome[iconName]);
    return <div style={{ fontSize: size, color: color }}>{render}</div>;
  }

  useEffect(() => {
    initForm();
  }, [])

  async function initForm() {
    await jwtAxios.get("/notificationstype/GetById/" + item.NotificationsTypeId).then((res) => {
      setIcon(res.data.icon);
    })
  }

  const setNotificationRead = (notification: any) => {
    notification.IsRead = true;
    jwtAxios.post("/notifications/update", notification).then((response) => {
      if (response.status === 200) {
        onNotificationRead(notification)
        dispatch(fetchSuccess());
          dispatch(
            showMessage(`Notificação: ${notification.Title}, marcada como lida.`)
          );
      }
    })
  }
  return (
    <ListItem
      sx={{
        padding: "8px 20px",
      }}
      className="item-hover"
    >

      <Box
        sx={{
          fontSize: 14,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Typography>
          <Box
            component="span"
            sx={{
              fontSize: 14,
              fontWeight: Fonts.MEDIUM,
              mb: 0.5,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
              display: "inline-block",
            }}
          >
            {item.Title}
          </Box>
          {item.Description}
        </Typography>
      </Box>
      <ListItemAvatar
        sx={{
          minWidth: 0,
          mr: 4,
        }}
      >
        {/* {icon && <Icon iconName={icon} size={12} color="black" />} */}
        <IconButton
          type="button"
          onClick={() => setNotificationRead(item)}
        >
          <ClearIcon />
        </IconButton>
      </ListItemAvatar>
      <AppInfoView />
    </ListItem>
  );
};

export default NotificationItem;
