const initialState = {
    filtro: '', // Valor inicial do filtro
    vision: '', // Valor inicial da visão
    enabledOnHeader: true, 
    filterDataTable:{
      pageName:'',
      filter:''
    },
    filterCustom:{
      pageName:'',
      filter:''
    },
  };
  
interface IFilterCustom {
pageName:string;
filter:any;
}

  // Defina uma ação para atualizar o filtro
  export type AtualizarFiltroAction = {
    type: 'ATUALIZAR_FILTRO';
    filtro: string;
    vision: string;
    enabledOnHeader: boolean;
    filterDataTable:IFilterCustom;
    filterCustom:IFilterCustom;
  };

  const Filter =(state = initialState, action: AtualizarFiltroAction) => {
    switch (action.type) {
      case 'ATUALIZAR_FILTRO':
        return { ...state, filtro: action.filtro, vision: action.vision, enabledOnHeader: action.enabledOnHeader, filterDataTable: action.filterDataTable, filterCustom: action.filterCustom} ;
      default:
        return state;
    }
  }

  export default Filter;