import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { AppAnimate, AppCard, AppGridContainer, AppInfoView, AppLoader } from "@crema";
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Popover, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuthUser } from "@crema/utility/AuthHooks";
import * as base from "../../../../../shared/basePages/base";


import * as FontAwesome from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { GetListData } from "shared/services/fetchRequests";
import IntlMessages from "@crema/utility/IntlMessages";
import moment from "moment";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { AppState } from "redux/store";
import { string } from "prop-types";
import { AiOutlineConsoleSql } from "react-icons/ai";
import jwtAxios from "@crema/services/auth/jwt-auth";
import Header from "shared/basePages/header";
import { SubmitButton, getFormElem } from "shared/basePages/forms/formElements";
import { Fonts } from "shared/constants/AppEnums";
import DataTable from "shared/basePages/DataTable";
import ControlledSelectionServerPaginationGrid from "shared/basePages/DataTableControlledSelection";
import { PieChart, pieArcClasses } from "@mui/x-charts/PieChart";

interface IFilter {
  andOrFilter: string,
  nameFilter: string,
  operadorFilter: string,
  valueFilter: string,
}

interface IFilterModel {
  columnField: string,
  operatorValue: string,
  value: string,
}



const FormList = ({ configs }) => {
  const [headerFilterData, setHeadrFilterData] = useState('');
  const [isLoadingPanelData, setIsLoadingPanelData] = useState(true);

  interface Investor {
    InvestorId: number;
    Name: string;
    CategoryName: string;
  }

  interface CategoryForm {
    id: number;
    value: number;
    label: string;
  }
  const [categoryData, setCategoryData] = useState<CategoryForm[]>([]);
  const [investors, setInvestors] = useState<Investor[]>([]);
  const headerFilter = (columnFilter, operadorFilter, valueFilter, functionChield) => {
    functionChield();
    setNameFilter(columnFilter);
    setOperadorFilter(operadorFilter);
    setValueFilter(valueFilter);

    setFilterModelValue({ columnField: '', operatorValue: '', value: '', });

    showFilter(columnFilter, operadorFilter, valueFilter);


  }

  interface CategoryData {
    name: string;
    amount: number;

  }
  const tableDefaut = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuthUser();
  const { messages } = useIntl();

  const [formData, setFormData] = useState({});
  const [labels, setLabels] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [processData, setProcessData] = useState(false);
  //let searchData: any[] | undefined = [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [countTotal, setCountTotal] = React.useState(0);
  const [urlId, setUrlId] = React.useState("");

  const formSchema = configs.List.filter;
  const header = configs.List.gridColumns;
  const actions = configs.List.actions;
  const dataParam = configs.Params;
  const isSearch = base.VerifyParam(configs.Params.isSearch, true);
  const buttonFilter = base.VerifyParam(configs.Params.buttonFilter, true);
  const buttonSelect = base.VerifyParam(configs.Params.buttonSelect, false);
  const isGetId = base.VerifyParam(configs.Params.isGetId, false);

  const isControlledSelection = base.VerifyParam(configs.Params.isControlledSelection, false);

  const isSearchInit = base.VerifyParam(configs.Params.isSearchInit, false);
  const isNoActive = base.VerifyParam(configs.Params.isNoActive, false);
  const enabledOnHeader = base.VerifyParam(configs.Params.enabledOnHeader, false);
  const { filtro, vision } = useSelector<AppState, AppState["filter"]>(({ filter }) => filter);
  const filter = filtro;
  const addButton = base.VerifyParam(configs.Params.ShowAddButton, true);
  const uploadButton = base.VerifyParam(configs.Params.ShowUploadButton, false);
  const functionModal = base.VerifyParam(configs.Params.functionModal, "");
  const actionUrl = base.VerifyParam(configs.Params.methodApi, "GetByFilter");

  const [andOrFilter, setAndOrFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [operadorFilter, setOperadorFilter] = useState("");
  const [valueFilter, setValueFilter] = useState("");
  //const [filterVisibility, setFilterVisibility] = useState(false);
  const [filterValues, setFilterValues] = useState<IFilter[]>([]);
  const [filterModel, setFilterModelValue] = useState<IFilterModel>();
  const [valueCategories, setValueCategories] = useState<CategoryData[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);




  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const AddFilterValues = (add: IFilter) => {
    // setFilterValues(current => [...current, update]);    
  }

  const handleName = (name) => {
    setNameFilter(name);
  }

  const showFilter = (columnFilterNew?, operadorFilterNew?, valueFilterNew?) => {

    let andOrFilterUpdate = andOrFilter;
    let nameFilterUpdate = nameFilter;
    let operadorFilterUpdate = operadorFilter;
    let valueFilterUpdate = valueFilter;

    if (columnFilterNew !== undefined && operadorFilterNew !== undefined && valueFilterNew !== undefined) {
      nameFilterUpdate = columnFilterNew;
      operadorFilterUpdate = operadorFilterNew;
      valueFilterUpdate = valueFilterNew;
    }
    if (valueFilterUpdate === '' && nameFilterUpdate === '' && operadorFilterUpdate === '') {
      return;
    }

    var newFilterValues: IFilter[] = [...filterValues];
    const update: IFilter = {
      andOrFilter: andOrFilterUpdate,
      nameFilter: nameFilterUpdate,
      operadorFilter: operadorFilterUpdate,
      valueFilter: valueFilterUpdate,
    }
    newFilterValues.push(update);

    newFilterValues = newFilterValues.filter(item => item.nameFilter != "SuitabilityExpirationDate");
    if (newFilterValues.length > 0 && newFilterValues[0].andOrFilter != "") {
      newFilterValues[0].andOrFilter = "";
    }
    //setFilterValues(current => [...current, update]);    
    setFilterValues(newFilterValues);
    handleClose();

    handleFilter(newFilterValues);

  };

  const handleChangeAndOr = (event: { target: { value: string } }) => {
    setAndOrFilter(event.target.value);

  }
  const handleChangeOperator = (event: { target: { value: string } }) => {
    setOperadorFilter(event.target.value);

  }
  const handleChangeValue = (event: { target: { value: string } }) => {
    setValueFilter(event.target.value);

  }

  const handleFilter = (newFilter: IFilter[]) => {

    handleClose();
    setAndOrFilter('and');
    var tBanco = "";
    var valueFilterNew = "";

    newFilter.map((campos, index) => {
      //var tchar = header.find(({ Field }) => Field === campos.nameFilter);

      var tchar = dataParam.filterColumns.find(({ Field }) => Field === campos.nameFilter);
      valueFilterNew += " " + campos.andOrFilter + " ";

      switch (tchar["type"]) {
        case 'String':
          if (campos.nameFilter === "TotalPL" || campos.nameFilter === "declaredPl" || campos.nameFilter === "annualIncome") {
            valueFilterNew += campos.nameFilter + " " + campos.operadorFilter + " " + campos.valueFilter;
          }
          else {
            //valueFilterNew += campos.nameFilter + ".toString().ToLower().Contains(\"" + campos.valueFilter.toLowerCase() + "\")";
            valueFilterNew += campos.nameFilter + ".ToLower().Contains(\"" + campos.valueFilter.toLowerCase() + "\")";
          }

          return;
        case 'DateTime':

          return 'bar';
        case 'date':

          return 'bar';
        case 'Int':

          return 'bar';
        default:
          valueFilterNew += campos.nameFilter + " " + campos.operadorFilter + " " + campos.valueFilter;
          return;
      }

    });

    tBanco += valueFilterNew;
    var tOrder = "";
    var jsonSend = {};

    // //Monta o Painel de filtro
    if (isNoActive) {
      jsonSend = { ParamSearch: "", StrCondition: tBanco, StrOrder: tOrder, TypeVision: vision };
    }
    else {
      jsonSend = { ParamSearch: { isActive: true }, UserId: filter !== "" ? filter : user?.userId, TypeVision: vision, StrCondition: tBanco, StrOrder: tOrder };
    }
    //var jsonSend = { StrCondition: tBanco, StrOrder: tOrder };

    SearchPagination(jsonSend, 0);
    //setFilterVisibility(true);
  }


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {

    dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader: enabledOnHeader, filterDataTable:'', filterCustom:'' });

    setSearchData([]);
    setProcessData(false);
    initForm();


  }, [filter, vision])




  async function initForm() {
    var _guidId = "";
    var _formData;
    var _labels;
    var _schema;


    dispatch(fetchStart());
    base.SetUrlApi(dataParam.urlApi);
    base.SetUrlFront(dataParam.urlFront);

    const filterExistis = filterValues.filter(item => item.nameFilter !== "SuitabilityExpirationDate");
    if (filterExistis.length > 0) {
      handleFilter(filterExistis);
    }
    else {

      if (isSearchInit) {
        var jsonSend = {};
        var ps = base.GetParam("ps");
        var condition: string | undefined = "";
        if (filterValues.length > 0) {
          //ps = null;
        }

        if (ps != null) {
          const date = new Date();
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          //var currentDay = date.getDate().toString();
          //currentDay = currentDay.length == 1 ? '0'+ currentDay : currentDay; 
          //var currentMonth = (date.getMonth() + 1).toString(); 
          //currentMonth = currentMonth.length == 1 ? '0'+ currentMonth : currentMonth; 
          switch (ps) {
            case 'd':
              var cond: IFilterModel = {
                columnField: 'DtBirth',
                operatorValue: 'contains',
                value: `${day}/${month}/`,
              };
              setFilterModelValue(cond);
              break;

            case 'm':
              var cond: IFilterModel = {
                columnField: 'DtBirth',
                operatorValue: 'contains',
                value: `/${month}/`,
              };
              setFilterModelValue(cond);
              break;
            case 's':
              const expirationDate = new Date(date.setDate(date.getDate() + 10));
              const expirationDay = String(expirationDate.getDate()).padStart(2, '0');
              const expirationMonth = String(expirationDate.getMonth() + 1).padStart(2, '0');
              const expirationYear = String(expirationDate.getFullYear());

              condition = setFilteParam(ps) ?? '';
              const update: IFilter = {
                andOrFilter: "",
                nameFilter: "SuitabilityExpirationDate",
                operadorFilter: "<=",
                valueFilter: `${expirationDay}/${expirationMonth}/${expirationYear}`,
              };

              setFilterValues(current => {
                // Verifica se o fitlro já existe
                const isDuplicate = current.some(filter =>
                  filter.nameFilter === update.nameFilter
                );
                // Se não exisiter adiciona
                if (!isDuplicate) {
                  return [...current, update];
                }
                // Se não exitir, retorna o array atual sem modificação
                return current;
              });
              jsonSend = { ParamSearch: "", StrCondition: condition, StrOrder: "", UserId: filter !== "" ? filter : user?.userId, TypeVision: vision };

              break;
          }
        }

        if (isNoActive) {
          jsonSend = { ParamSearch: "", StrCondition: condition, StrOrder: "", TypeVision: vision };
        }
        else {
          jsonSend = { ParamSearch: { isActive: true }, UserId: filter !== "" ? filter : user?.userId, TypeVision: vision, StrCondition: condition, StrOrder: "" };
        }


        /*
              if (ps != null) {
                var condition = setFilteParam(ps);
          
                jsonSend = { ParamSearch: "", StrCondition: condition, StrOrder: "", UserId: filter !== ""? filter: user?.userId,TypeVision:vision };
      
              }
              else {
                if(isNoActive){
                  jsonSend = { ParamSearch: "", StrCondition: "", StrOrder: "",TypeVision:vision };
                }
                else{
                  jsonSend = { ParamSearch: { isActive: true, UserId: filter !== ""? filter: user?.userId }, TypeVision:vision, StrCondition: "", StrOrder: "" };
                }
                //jsonSend = { ParamSearch: { isActive: true }, StrCondition: "", StrOrder: "" };
              }      
              */


        SearchPagination(jsonSend, 1);
      }
    }


    [_formData, _labels, _schema] = base.SetIniFields(true, formSchema, _guidId);

    setFormData(_formData);
    setLabels(_labels);
    setValidationSchema(_schema);



    dispatch(fetchSuccess());
  }

  async function SearchPagination(jsonSend, page) {

    var _dataList;
    var _qtdRecords;

    var paramBusca = { CountRecordsPerPage: rowsPerPage, Page: page }
    var paramFilter = Object.assign(jsonSend, paramBusca);

    if (isGetId) {
      if (configs.id !== '' && configs.id !== undefined) {

        paramFilter = configs.id;
        setUrlId(configs.id);
      }
      else {
        var gId = base.GetIdUrl();
        paramFilter = { id: gId && gId[0] };

      }
      //console.log("param", paramFilter)
    }

    [_qtdRecords, _dataList] = await base.SearchRecords(actionUrl, paramFilter);

    setProcessData(true);
    if (_dataList !== undefined) {
      const grouped: Record<number, { count: number; name: string }> = {};

      _dataList.forEach(investor => {
        if (!grouped[investor.InvestorCategoryId]) {
          grouped[investor.InvestorCategoryId] = {
            count: 1,
            name: investor.InvestorCategoryName
          };
        } else {
          grouped[investor.InvestorCategoryId].count += 1;
        }
      });

      const result: CategoryForm[] = Object.values(grouped).map((group, index) => ({
        id: index + 1,
        label: group.name,
        value: group.count
      }));

      setCategoryData(result);
     
      setSearchData(_dataList);
      setCountTotal(_qtdRecords);
    } else {
      setSearchData([]);
    }

  }
  const categoryMock = [
    { id: 0, value: 1, label: 'DIAMANTE' },
    { id: 1, value: 1, label: 'BLACK' },
    { id: 3, value: 1, label: 'PLATINUM' },
    { id: 4, value: 1, label: 'SAFIRA' },
    { id: 5, value: 1, label: 'ESMERALDA' },
    { id: 6, value: 1, label: 'GOLD' },
    { id: 7, value: 1, label: 'SILVER' },
    { id: 8, value: 1, label: 'BRONZE' },

  ];
  const serviceStatus = [
    { id: 0, value: 1, label: 'Atualizado' },
    { id: 1, value: 1, label: 'Atrasado' },

  ];
  const aderencyStatus = [
    { id: 0, value: 1, label: 'Aderente' },
    { id: 1, value: 1, label: 'Não Aderente' },

  ];

  const handleReloadData = () => {
    dispatch(fetchStart());
    setTimeout(() => {
      //setSearchData([]);    
      initForm();
    }, 1800);
    dispatch(fetchSuccess());
  }

  const setFilteParam = (ps) => {
    const update: IFilter[] = [...filterValues];
    var cond = "";
    switch (ps) {
      case 'd':
        update.map((campos: IFilter, index) => {
          if (campos.nameFilter === nameFilter) {
            update[index].andOrFilter = "";
            update[index].nameFilter = "DtBirth";
            update[index].operadorFilter = "=";
            update[index].valueFilter = "day";
          }
        });
        setFilterValues(update);
        cond = "DtBirth.Date.Month == DateTime.Now.Date.Month ";
        cond += "and DtBirth.Date.Day == DateTime.Now.Date.Day ";



        return cond;
      case 'm':
        update.map((campos: IFilter, index) => {
          if (campos.nameFilter === nameFilter) {
            update[index].andOrFilter = "";
            update[index].nameFilter = "DtBirth";
            update[index].operadorFilter = "=";
            update[index].valueFilter = "month";
          }
        });
        setFilterValues(update);
        cond = "DtBirth.Date.Month == DateTime.Now.Date.Month";
        return cond;
      case 's':
        update.map((campos: IFilter, index) => {
          const date = new Date();
          const expirationDate = new Date(date.setDate(date.getDate() + 10));
          const expirationDay = String(expirationDate.getDate()).padStart(2, '0');
          const expirationMonth = String(expirationDate.getMonth() + 1).padStart(2, '0');
          const expirationYear = String(expirationDate.getFullYear());
          if (campos.nameFilter === nameFilter) {
            update[index].andOrFilter = "";
            update[index].nameFilter = "SuitabilityExpirationDate";
            update[index].operadorFilter = ">=";
            //update[index].valueFilter = "DateTime.Now.Date";
            update[index].operadorFilter = `${expirationDay}/${expirationMonth}/${expirationYear}`;

          }
        });
        setFilterValues(update);
        // cond = " SuitabilityExpirationDate.Date.Year == DateTime.Today.Date.Year ";
        // cond += " and SuitabilityExpirationDate.Date.Month == DateTime.Today.Date.Month ";
        cond += "SuitabilityExpirationDate >= DateTime.Today.Date";
        cond += " and SuitabilityExpirationDate <= DateTime.Today.AddDays(10) ";
        return cond;
    }
  }


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { target: { name, value } } = event;

    setFormData({ ...formData, [name]: value });
  }

  const handleChangePage = async (event: unknown, newPage: number) => {

    //var jsonSend = {};        
    var jsonSend = { ParamSearch: formData };
    SearchPagination(jsonSend, newPage + 1);
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    var jsonSend = {};
    setRowsPerPage(+event.target.value);
    var ps = base.GetParam("ps");
    if (ps != null) {
      var condition = setFilteParam(ps);

      jsonSend = { ParamSearch: null, StrCondition: condition, StrOrder: "" };
    }
    else {
      if (isNoActive) {
        jsonSend = { ParamSearch: "", StrCondition: "", StrOrder: "" };
      }
      else {
        jsonSend = { ParamSearch: { isActive: true, UserId: filter !== "" ? filter : user?.userId }, TypeVision: vision, StrCondition: "", StrOrder: "" };
      }

    }
    //var jsonSend = {ParamSearch:  formData};  


    SearchPagination(jsonSend, 0);
    setPage(0);
  };

  const handleSubmit = (event, buttonName) => {

    if (buttonName === 'cancel') {
      initForm();
    }

  }

  const handleClickButton = (selectId) => {
    dispatch(fetchStart());
    handleClose();
    if (selectId !== "") {
      var gId = base.GetIdUrl();
      var Id = gId && gId[0];

      const jsonSend = { id: Id, ids: selectId }
      jwtAxios.post(dataParam.urlApi + "/DeleteRangeId", jsonSend).then((res) => {

        dispatch(fetchSuccess());
        setTimeout(() => {
          //setSearchData([]);    
          initForm();
        }, 1800);
        dispatch(showMessage('Sucess.'))

      })


    }
  };

  const RemoveFilter = (andOrFilter, nameFilter, operadorFilter, valueFilter) => {

    const update: IFilter[] = [...filterValues];

    update.map((campos: IFilter, index) => {
      if (campos.nameFilter === nameFilter && campos.andOrFilter === andOrFilter && campos.operadorFilter === operadorFilter && campos.valueFilter === valueFilter) {
        update.splice(index, 1);
      }
    });

    if (update.length > 0 && update[0].andOrFilter != "") {
      update[0].andOrFilter = "";
    }
    setFilterValues(update);
    handleFilter(update);
  }



  return (
    <>
      <AppGridContainer>
        <Stack direction="row" width="100%" textAlign="center" spacing={2} marginBottom={10}>
          <Box flexGrow={1} style={{ marginRight: "10px" }} >
            <Paper style={{ borderRadius: "10px" }}>
              <PieChart
                series={[
                  {
                    data: categoryData !== undefined ? categoryData : categoryMock,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30 },
                  },
                ]}
                sx={{
                  [`& .${pieArcClasses.faded}`]: {
                    fill: 'gray',
                  },
                }}
                height={250}
              />
              <Typography>Categoria dos Clientes</Typography>
            </Paper>
          </Box>
          <Box flexGrow={1} style={{ marginRight: "10px" }} >
            <Paper style={{ borderRadius: "10px", fontSize: "10px" }}>
              <PieChart
                series={[
                  {
                    data: serviceStatus,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30 },
                  },

                ]}

                sx={{
                  [`& .${pieArcClasses.faded}`]: {
                    fill: 'gray',
                  },
                }}
                height={250}
              />
              <Typography>Status de Atendimento</Typography>
            </Paper>
          </Box>
          <Box flexGrow={1} >
            <Paper style={{ borderRadius: "10px" }}>
              <PieChart
                series={[
                  {
                    data: aderencyStatus,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30 },
                  },
                ]}
                sx={{
                  [`& .${pieArcClasses.faded}`]: {
                    fill: 'gray',
                  },
                  [`& .${pieArcClasses.root}`]: {
                    fontSize: 10
                  }
                }}
                height={250}
              />
              <Typography>Aderente</Typography>
            </Paper>
          </Box>
        </Stack>
      </AppGridContainer>

      {isSearch &&
        <>
          <AppAnimate animation="transition.slideUpIn" delay={200}>
            <AppCard>
              <Header dataParam={dataParam} showButtonAdd={false} showButtons={false} showButtonFilter={false} showButtonSelect={false} showButtonUpload={false} showButtonBack={true} />
              <Formik
                enableReinitialize
                validateOnChange={true}
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {

                  actions.setSubmitting(true);
                  dispatch(fetchStart());
                  if (values) {
                    var jsonSend = { ParamSearch: values };
                    SearchPagination(jsonSend, 0);
                    //setSearchData(await base.SearchRecords(actionUrl, values));

                    dispatch(fetchSuccess());
                    dispatch(
                      showMessage('Sucess.')
                    )
                  }
                  else {
                    dispatch(fetchError('Cannot post this data. Please verify the fields and try again.'));
                  }

                  actions.setSubmitting(false);
                  actions.resetForm();
                }}

              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <AppGridContainer spacing={4} heigth={500}>
                      {Object.keys(formSchema).map((key, index) => {
                        var props = {
                          labels: labels,
                          elemName: key,
                          value: formData[key],
                          elementSchema: formSchema[key],
                          errors: errors[key],
                          touched: touched[key],
                          handleChange: handleChange,
                          allStates: null,
                          index: index
                        }
                        return (
                          getFormElem(props)
                        )
                      })
                      }
                      <SubmitButton {...{ isSubmitting: isSubmitting, name: 'find', handleSubmit: handleSubmit }} />
                    </AppGridContainer>
                  </Form>
                )}
              </Formik>
            </AppCard>
          </AppAnimate>
          <br></br>
        </>
      }
      <AppAnimate animation="transition.slideUpIn" delay={200}>
        <AppCard>
          <Grid item xs={12} md={12}>
            <Header
              dataParam={dataParam}
              showButtonAdd={addButton}
              showButtons={false}
              showButtonFilter={buttonFilter}
              filterFunction={headerFilter}
              showButtonSelect={buttonSelect}
              selectFunction={handleReloadData}
              urlId={urlId}
              showButtonUpload={uploadButton}
              showButtonBack={true}
            />
            {filterValues.length > 0 &&
              <Box
                sx={{
                  flex: 1,
                }}

              >
                <Box
                  component='h3'
                  sx={{
                    color: 'text.primary',
                    fontWeight: Fonts.BOLD,
                    fontSize: 16,
                    mb: 1,
                  }}
                >
                  <IntlMessages id={"appliedfilters"} />
                </Box>

                {filterValues.map((item, index) => {

                  return (
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Box
                        sx={{
                          ml: 2,
                          mr: 4,
                          color: 'text.secondary',
                        }}
                      >

                        {item.andOrFilter + " " + String(messages["common." + item.nameFilter.toLowerCase()]) + " " + item.operadorFilter + " " + item.valueFilter} - <DeleteIcon style={{ cursor: "pointer" }} onClick={() => RemoveFilter(item.andOrFilter, item.nameFilter, item.operadorFilter, item.valueFilter)} />
                      </Box>
                    </Box>
                  )
                })}

              </Box>
            }


            <Paper sx={{ mt: 5, width: "100%", overflow: "hidden" }}>
              {!isControlledSelection
                ?
                <DataTable
                  rowData={searchData}
                  rowHead={header}
                  actions={actions}
                  processData={processData}
                  filterModeDatal={filterModel}
                  selectFunction={handleReloadData}
                  modalFunction={functionModal}
                />
                :

                <ControlledSelectionServerPaginationGrid
                  {...{
                    rowData: searchData,
                    rowHead: header,
                    processData: processData,
                    buttonName: dataParam.buttonDeleteName,
                    buttonFunction: handleClickButton

                  }}
                />

              }
              {
                !tableDefaut &&
                <>

                  <TableContainer sx={{
                    "&::-webkit-scrollbar": {
                      width: 8,
                      height: 8
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "primary.main",
                      borderRadius: 2
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#757575",
                      borderRadius: 2
                    },
                    maxHeight: 440
                  }}>

                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {header.map((res, key) => (
                            res.show && (
                              <TableCell key={key} style={{ textAlign: res.align ? res.align : "left", padding: 1 }}>
                                {!isSearch && res.isSearch ?
                                  <>
                                    <Button aria-describedby={id}
                                      onClick={function (event) { handleClick(event); handleName(res.Field); }}
                                    >
                                      {messages[`${String(res.id).toLowerCase()}`]}
                                      <FilterAltOutlinedIcon sx={{ fontSize: 'small' }} />
                                    </Button>
                                  </>
                                  :
                                  <Button aria-describedby={id} style={{ pointerEvents: "none" }}>
                                    {messages[`${(res.id).toLowerCase()}`]}
                                  </Button>
                                }
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  PaperProps={{
                                    style: {
                                      backgroundColor: "transparent",
                                      boxShadow: "none",
                                      borderRadius: 1,
                                      overflow: "hidden"
                                    }
                                  }}
                                ><Box
                                    sx={{
                                      position: "relative",
                                      //mt: "-10px",
                                      "&::before": {
                                        backgroundColor: "white",
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        width: 12,
                                        height: 12,
                                        top: 104,
                                        transform: "rotate(45deg)",
                                        left: "calc(50% - 6px)",
                                        borderBottom: 1,
                                        borderRight: 1,
                                        borderColor: "#cccccc"
                                      }
                                    }}
                                  />
                                  <Typography sx={{ height: 110, width: 600, mb: "20px", backgroundColor: "white", border: 1, borderColor: "#cccccc" }}>
                                    <Grid item xs={12}>
                                      <Typography sx={{ p: 1, backgroundColor: "#0D4B7C", color: "white" }}>
                                        Adicionar filtro
                                      </Typography>
                                    </Grid>
                                    <Grid container spacing={1} sx={{ p: 2 }}>
                                      {/*
                                  //<Grid item >
                                  //  <Grid item xs={12}>
                                  //    Logica
                                  //  </Grid>
                                  //  <Grid item xs={12}>
                                  //    <FormControl sx={{ minWidth: 70 }} size="small">
                                  //      <Select
                                  //        value={andOrFilter}
                                  //        onChange={handleChangeAndOr}
                                  //        displayEmpty
                                  //        inputProps={{ 'aria-label': 'Without label' }}
                                  //      >
                                  //        <MenuItem value="and">{"And"}</MenuItem>
                                  //        <MenuItem value="or">{"Or"}</MenuItem>
                                  //      </Select>
                                  //    </FormControl>
                                  //  </Grid>
                                  //</Grid>
                                  */}
                                      <Grid item xs={3}>
                                        <Grid item xs={12}>
                                          Coluna
                                        </Grid>
                                        <Grid item xs={12}>
                                          <FormControl sx={{ minWidth: 100 }} size="small">
                                            <TextField
                                              disabled
                                              variant="outlined"
                                              fullWidth
                                              size="small"
                                              value={String(messages["common." + nameFilter.toLowerCase()])}
                                            />
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Grid item xs={12}>
                                          Operador
                                        </Grid>
                                        <Grid item xs={12}>
                                          <FormControl sx={{ minWidth: 70 }} size="small">
                                            <Select
                                              value={operadorFilter}
                                              onChange={handleChangeOperator}
                                              displayEmpty
                                              inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                              <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem>
                                              <MenuItem value=">">{">"}</MenuItem>
                                              <MenuItem value="<">{"<"}</MenuItem>
                                              <MenuItem value="=">{"="}</MenuItem>
                                              <MenuItem value=">=">{">="}</MenuItem>
                                              <MenuItem value="<=">{"<="}</MenuItem>
                                              <MenuItem value="!=">{"!="}</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Grid item xs={12}>
                                          Valor
                                        </Grid>
                                        <Grid item xs={12}>
                                          <FormControl sx={{ minWidth: 100 }} size="small">
                                            <TextField
                                              variant="outlined"
                                              size="small"
                                              onChange={handleChangeValue}
                                            />
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            right: 0,
                                            bottom: 0,

                                          }}
                                        >
                                          <Button
                                            sx={{
                                              position: "relative",
                                              minWidth: 60,
                                              top: 20
                                            }}
                                            color="primary"
                                            variant="contained"
                                            type="button"
                                            onClick={showFilter}
                                          //disabled={isSubmitting}
                                          >
                                            <IntlMessages id={"common.filter"} />
                                          </Button>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                </Popover>
                              </TableCell>
                            )
                          ))}
                        </TableRow>
                      </TableHead>
                   
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={countTotal}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              }
            </Paper>

          </Grid>
        </AppCard>

      </AppAnimate>
      <AppInfoView />

    </>
  );



}
export default FormList;

