import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import IntlMessages from "@crema/utility/IntlMessages";

const ButtonBack = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
      };

return (
    <Button variant="contained" sx={{ mr: 2 } } onClick={handleGoBack} >
        <KeyboardBackspaceOutlinedIcon sx={{ fontSize: 20 }} />
        <IntlMessages id={"GoBack"} />
    </Button>
)
  }
    export default ButtonBack;