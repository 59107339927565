import React from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import AuthRoutes from "@crema/utility/AuthRoutes";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppLayout from "@crema/core/AppLayout";
import configureStore from "redux/store";
import JWTAuthProvider, { TenantProvider } from "./@crema/services/auth/jwt-auth/JWTAuthProvider";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClient } from "shared/services/queryClient";
import FilterProvider from "@crema/services/filter/FilterProvider";

const store = configureStore();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AppContextProvider>
      <Provider store={store}>
        <TenantProvider>
          <FilterProvider>
            <AppThemeProvider>
              <AppStyleProvider>
                <AppLocaleProvider>
                  <BrowserRouter>
                    <JWTAuthProvider>
                      <AuthRoutes>
                        <CssBaseline />
                        <AppLayout />
                      </AuthRoutes>
                    </JWTAuthProvider>
                  </BrowserRouter>
                </AppLocaleProvider>
              </AppStyleProvider>
            </AppThemeProvider>
          </FilterProvider>
        </TenantProvider>
      </Provider>
    </AppContextProvider>
  </QueryClientProvider>
);

export default App;
