import React from "react";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import { alpha, Box } from "@mui/material";
import ActivesInfo from "./info";
import { Fonts } from "../../../../shared/constants/AppEnums";
import AppCard from "@crema/core/AppCard";
import { TotalPL } from "../../mock";
import { ContributionsAndWithdrawalsPanelData } from "../NotificationsPanel";
import ContributionInfo from "./info";

interface ContributionsAndWithdrawalsProps {
  contributionsAndWithdrawalsPanelData: ContributionsAndWithdrawalsPanelData[];
}

const Contribution: React.FC<ContributionsAndWithdrawalsProps> = ({ contributionsAndWithdrawalsPanelData }) => {
  let totalContributions = 0; 
  let totalWithdrawals= 0;
  
  contributionsAndWithdrawalsPanelData.forEach((CWPanelData) => {
    totalContributions += CWPanelData.Contribution;
    totalWithdrawals += CWPanelData.Withdrawal;
  });

  return (
    <Box>

      <AppCard sxStyle={{}}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              component="h2"
              sx={{
                color: "text.primary",
                fontSize: 16,
                //
                mb: { xs: 4, sm: 4, xl: 3 },
                fontWeight: Fonts.BOLD,
              }}
            >
              <IntlMessages id="contributions.withdrawals" />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  mt: { xl: 3 },
                }}
              >
                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    color: "primary.main",
                  }}
                >
                 {totalContributions? Intl.NumberFormat('pt-BR').format(Number(totalContributions)): "-"}
                </Box>
                <Box
                  component="p"
                  sx={{
                    color: "text.secondary",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id="contribution" />
                </Box>
                </Box>
                <Box
                sx={{
                  ml: 20,
                  mt: { xl: 3 },
                  px: 2,
                }}
              >

                <Box
                  component="h3"
                  sx={{
                    fontWeight: Fonts.BOLD,
                    fontSize: 20,
                    color: "primary.main",
                  }}
                >
                   {totalWithdrawals? Intl.NumberFormat('pt-BR').format(Number(totalWithdrawals)): "-"}
                </Box>
                <Box
                  component="p"
                  sx={{ 
                    color: "text.secondary",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    mb: 1,
                  }}
                >
                  <IntlMessages id="withdrawal" />
                
                </Box>
              </Box>

            </Box>
          </Box>

        </Box>
        <Box
          sx={{
            pt: { md: 2, lg: 2 },
          }}
        >
          <ContributionInfo key={totalContributions + totalWithdrawals} contributionInfo={contributionsAndWithdrawalsPanelData}/>
        </Box>
      </AppCard>
    </Box>
  );
};

export default Contribution;
