import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { now } from 'moment';
import 'moment/locale/pt-br';
import { StyledCalendar } from "../calandar.style";
import Box from "@mui/material/Box";
import AddNewTask from "pages/admin/events";
import AppDialog from "@crema/core/AppDialog";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { useAuthUser } from "@crema/utility/AuthHooks";
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchStart, fetchSuccess, showEventConfirmation, showMessage } from "redux/actions";
import { AppInfoView } from "@crema";
import AppEventConfirmationView from "@crema/core/AppEventConfirmationView";
import events, { EventProps } from "../events";
import { AppState } from "redux/store";
import { date } from "yup";

interface EventsData {
  title: string;
  desc: string;
  end: Date;
  start: Date;
  email: string;
  hourEnd: string;
  hourStart: string;
  place: string;
  eventId: string;
  eventTypeId: string;
  investorId: string;
  phoneNumber: string;
  scheduleId: string;
  tenantId: string;
  userId: string;
  isActive: boolean;
  interactionTypeId: string;
  idEventOutlook: string;
  
}
const localizer = momentLocalizer(moment);




const Selectable = () => {
  const [eventsData, setEventsData] = useState<EventsData[]>([]);
  const dispatch = useDispatch();
  const { user } = useAuthUser();
  const { filtro, vision } = useSelector<AppState, AppState["filter"]>(({ filter }) =>  filter);
    const filter = filtro;
  const [singleEvent, setSingleEvent] = useState<EventProps[]>([]);
  useEffect(() => {
    dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader:true, filterDataTable:'',filterCustom:''});
     getEvents();
  
  }, [filter,vision])


  

  
  const getEvents = (date?: Date) => {
    let initialState: EventsData[] = [];
    setEventsData(initialState);
    dispatch(fetchStart());
    
    jwtAxios.post("events/getbyfilter", { ParamSearch: { UserId: filter !== "" ? filter : user?.userId}, TypeVision:vision}).then((res) => {
      
      res.data.List.map((item: any) => {

        var now = date !== undefined ? date :new Date()

        if (new Date(item.DtStart).getMonth() === now.getMonth() && new Date(item.DtStart).getFullYear() === now.getFullYear()) {
          let eventsItem: EventsData = {
            title: item.Title,
            end: new Date(item.DtEnd),
            start: new Date(item.DtStart),
            desc: item.Description,
            email: item.Email,
            isActive: true,
            investorId: item.InvestorId,
            place: item.Place,
            hourEnd: item.HourEnd,
            hourStart: item.HourStart,
            eventId: item.EventId,
            eventTypeId: item.EventTypeId,
            phoneNumber: item.PhoneNumber,
            scheduleId: item.ScheduleId,
            tenantId: item.TenantId,
            userId: item.UserId,
            interactionTypeId: item.InteractionTypeId,
            idEventOutlook: item.IdEventOutlook
            
          }
          setEventsData(current => [...current, eventsItem]);
        }
      })
      dispatch(fetchSuccess());
    })
  }
  const [selected, setSelected] = useState();
  const [event, setEvent] = useState<any>();

  const [modalView, setModalView] = useState<boolean>(false);
  const handleEventClick = (event) => {
    setModalView(true);

  };
  const handleClose = () => {
   
        getEvents();   
        
  };

  
  return (
    <div className="app-calendar app-cul-calendar">
      <StyledCalendar
        selectable
        events={eventsData}
        // events={events}
        localizer={localizer}
        defaultView="month"
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        selected={selected}
        onSelectEvent={(event) => {          
          setEvent(event);
          setModalView(true);
        }}
        onSelectSlot={(event) => {
          setEvent(event);
          setModalView(true);
        }}
        messages={{
          next: "Próximo",
          previous: "Anterior",
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          showMore: total => `+ ${total} eventos`,

        }}
        onNavigate={date => {
          getEvents(date);
        }}
      />
      <Dialog
        open={modalView}
        onClose={() => setModalView(false)}
        title=""
        PaperProps={{
          sx: {
            minHeight: '80vh',
            maxHeight: '80vh',
            minWidth: '80vh',
            maxWidth: '80vh',
          }
        }}
      >
        <Toolbar>


          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Agenda
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setModalView(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        {event && (
          <AddNewTask selectedEvent={event} fuctionSelected={setModalView} functionCloseChield={handleClose}/>
        )}
      </Dialog>
      <AppInfoView />
    </div>
  );
};

export default Selectable;
