import { useAuthUser } from "@crema/utility/AuthHooks";
import { UploadFile } from "@mui/icons-material";
import EvaDashboard from "pages/dashboard/clickmeDashboard";
import Calendar from "pages/thirdParty/calendar";
import React from "react";
import Commission from "./commission";

const Systems = React.lazy(() => import("./systems"));
const SystemsList = React.lazy(() => import("./systems/list"));
const Menus = React.lazy(() => import("./menus"));
const MenusList = React.lazy(() => import("./menus/list"));
const Profiles = React.lazy(() => import("./profiles"));
const ProfilesList = React.lazy(() => import("./profiles/list"));
const Languages = React.lazy(() => import("./languages"));
const LanguagesList = React.lazy(() => import("./languages/list"));
const Groups = React.lazy(() => import("./investorgroups"));
const GroupsList = React.lazy(() => import("./investorgroups/list"));
const GroupsTypes = React.lazy(() => import("./groupsTypes"));
const GroupsTypesList = React.lazy(() => import("./groupsTypes/list"));
const Tenants = React.lazy(() => import("./tenants"));
const TenantsList = React.lazy(() => import("./tenants/list"));
const TenantsTypes = React.lazy(() => import("./tenantstypes"));
const TenantsTypesList = React.lazy(() => import("./tenantstypes/list"));

const Departments = React.lazy(() => import("./departments"));
const DepartmentsList = React.lazy(() => import("./departments/list"));

const Sites = React.lazy(() => import("./sites"));
const SitesList = React.lazy(() => import("./sites/list"));

const Skills = React.lazy(() => import("./skills"));
const SkillsList = React.lazy(() => import("./skills/list"));

const InvestorService = React.lazy(() => import("./investorService"));
const InvestorServiceList = React.lazy(() => import("./investorService/list"));

const ServicesChannel = React.lazy(() => import("./servicesChannel"));
const ServicesChannelList = React.lazy(() => import("./servicesChannel/list"));

const ServicesResult = React.lazy(() => import("./servicesResult"));
const ServicesResultList = React.lazy(() => import("./servicesResult/list"));

const ServicesReason = React.lazy(() => import("./servicesReason"));
const ServicesReasonList = React.lazy(() => import("./servicesReason/list"));

const Companies = React.lazy(() => import("./companies"));
const CompaniesList = React.lazy(() => import("./companies/list"));

const SocialMedia = React.lazy(() => import("./socialmedia"));
const SocialMediaList = React.lazy(() => import("./socialmedia/list"));

const Occupations = React.lazy(() => import("./occupations"));
const OccupationsList = React.lazy(() => import("./occupations/list"));

const Users = React.lazy(() => import("./users"));
const MyAccount = React.lazy(() => import("./myaccount"));
const UsersList = React.lazy(() => import("./users/list"));

const Hierarchies = React.lazy(() => import("./hierarchies"));
const HierarchiesList = React.lazy(() => import("./hierarchies/list"));


const PasswordRules = React.lazy(() => import("./passwordrules"));
const PasswordRulesList = React.lazy(() => import("./passwordrules/list"));


const PasswordPolicies = React.lazy(() => import("./passwordpolicies"));
const PasswordPoliciesList = React.lazy(() => import("./passwordpolicies/list"));

const Investors = React.lazy(() => import("./investors"));
const InvestorsList = React.lazy(() => import("./investors/list"));
const AccountMovements = React.lazy(() => import("./investors/accountMovements"));
const InvestorDetail = React.lazy(() => import("./investors/investorDetail"));

const InvestorProfile = React.lazy(() => import("./InvestorProfile"));
const InvestorProfileList = React.lazy(() => import("./InvestorProfile/list"));

const InvestorRange = React.lazy(() => import("./InvestorRange"));
const InvestorRangeList = React.lazy(() => import("./InvestorRange/list"));

const ActivesInvestorsDetail = React.lazy(() => import("./investors/investorActivesDetail"));

const InvestorType = React.lazy(() => import("./InvestorType"));
const InvestorTypeList = React.lazy(() => import("./InvestorType/list"));

const Wallets = React.lazy(() => import("./wallets"));
const WalletsList = React.lazy(() => import("./wallets/list"));

const EventsTypes = React.lazy(() => import("./eventstypes"));
const EventsTypesList = React.lazy(() => import("./eventstypes/list"));

const InvestorsGroups = React.lazy(() => import("./investorsgroups"));
const InvestorsGroupsList = React.lazy(() => import("./investorsgroups/list"));

const Notifications = React.lazy(() => import("./notifications"));
const NotificationsList = React.lazy(() => import("./notifications/list"));

const NotificationsType = React.lazy(() => import("./notificationsType"));
const NotificationsTypeList = React.lazy(() => import("./notificationsType/list"));

const CurrentAccountList = React.lazy(() => import("./currentaccount/list"));

const Prospects = React.lazy(() => import("./prospects"));
const ProspectsList = React.lazy(() => import("./prospects/list"));

const NewAccountsList = React.lazy(() => import("./newaccounts/list"));

const AdvisorInvestorsReports = React.lazy(() => import("./reports/advisors/investors"));

const AccountsApi = React.lazy(() => import("./accountsApi"));
const AccountsApiList = React.lazy(() => import("./accountsApi/list"));

const Settings = React.lazy(() => import("./settings"));

const UploadFilesList = React.lazy(() => import("./uploadfiles/list"));
const UploadFiles = React.lazy(() => import("./uploadfiles"));

const Reportb2bList = React.lazy(() => import("./reportB2B/list"));
//const Reportb2b = React.lazy(() => import("./reportB2B"));

const InvestorsCategoriesList = React.lazy(() => import("./investorsCategories/list"));
const InvestorsCategories = React.lazy(() => import("./investorsCategories"));

const CampaignsList = React.lazy(() => import("./campaigns/list"));
const Campaigns = React.lazy(() => import("./campaigns"));

const Scripts = React.lazy(() => import("./scripts"));
const ScriptsList = React.lazy(() => import("./scripts/list"));

const InvestorsBullets = React.lazy(() => import("./investorsBullets"));
const InvestorsBulletsList = React.lazy(() => import("./investorsBullets/list"));

const OutlookIntegration = React.lazy(() => import("./outlookIntegration"));
const OutlookIntegrationList = React.lazy(() => import("./outlookIntegration/list"));


export var adminPagesConfigs = [
  {
    path: "/admin/passwordpolicies",
    element: <PasswordPolicies />,

  },
  {
    path: "/admin/passwordpolicies/:id",
    element: <PasswordPolicies />,
  },
  {
    path: "/admin/passwordpolicies/list",
    element: <PasswordPoliciesList />,
  },
  {
    path: "/admin/passwordrules",
    element: <PasswordRules />,
  },
  {
    path: "/admin/passwordrules/:id",
    element: <PasswordRules />,
  },
  {
    path: "/admin/passwordrules/list",
    element: <PasswordRulesList />,
  },
  {
    path: "/admin/hierarchies",
    element: <Hierarchies />,
  },
  {
    path: "/admin/hierarchies/:id",
    element: <Hierarchies />,
  },
  {
    path: "/admin/hierarchies/list",
    element: <HierarchiesList />,
  },

  {
    path: "/admin/systems",
    element: <Systems />,
  },
  {
    path: "/admin/systems/:id",
    element: <Systems />,
  },
  {
    path: "/admin/systems/list",
    element: <SystemsList />,
  },
  {
    path: "/admin/menus",
    element: <Menus />,
  },
  {
    path: "/admin/menus/:id",
    element: <Menus />,
  },
  {
    path: "/admin/menus/list",
    element: <MenusList />,
  },
  {
    path: "/admin/profiles",
    element: <Profiles />,
  },
  {
    path: "/admin/profiles/:id",
    element: <Profiles />,
  },
  {
    path: "/admin/profiles/list",
    element: <ProfilesList />,
  },
  {
    path: "/admin/groups",
    element: <Groups />,
  },
  {
    path: "/admin/groups/:id",
    element: <Groups />,
  },
  {
    path: "/admin/groups/list",
    element: <GroupsList />,
  },
  {
    path: "/admin/commission",
    element: <Commission />,
  },
  {
    path: "/admin/investorsGroupsTypes",
    element: <GroupsTypes />,
  },
  {
    path: "/admin/investorsGroupsTypes/:id",
    element: <GroupsTypes />,
  },
  {
    path: "/admin/investorsGroupsTypes/list",
    element: <GroupsTypesList />,
  },
  {
    path: "/admin/tenants",
    element: <Tenants />,
  },
  {
    path: "/admin/tenants/:id",
    element: <Tenants />,
  },
  {
    path: "/admin/tenants/list",
    element: <TenantsList />,
  },
  {
    path: "/admin/tenantstypes/:id",
    element: <TenantsTypes />,
  },
  {
    path: "/admin/tenantstypes",
    element: <TenantsTypes />,
  },
  {
    path: "/admin/tenantstypes/list",
    element: <TenantsTypesList />,
  },
  {
    path: "/admin/departments/:id",
    element: <Departments />,
  },
  {
    path: "/admin/departments",
    element: <Departments />,
  },
  {
    path: "/admin/departments/list",
    element: <DepartmentsList />,
  },
  {
    path: "/admin/users",
    element: <Users />,
    menuName: "users"
  },
  {
    path: "/admin/users/:id",
    element: <Users />,
  },

  {
    path: "/admin/users/list",
    element: <UsersList />,
  },
  {
    path: "/admin/myaccount",
    element: <MyAccount />,
  },
  {
    path: "/admin/schedule",
    element: <Calendar />,
  },
  {
    path: "/admin/languages",
    element: <Languages />,
  },
  {
    path: "/admin/languages/:id",
    element: <Languages />,
  },
  {
    path: "/admin/languages/list",
    element: <LanguagesList />,
  },
  {
    path: "/admin/sites/:id",
    element: <Sites />,
  },
  {
    path: "/admin/sites",
    element: <Sites />,
  },
  {
    path: "/admin/sites/list",
    element: <SitesList />,
  },
  // {
  //   path: "/admin/investorservice",
  //   element: <InvestorService />,
  // },
  // {
  //   path: "/admin/investorservice/list",
  //   element: <InvestorServiceList />,
  // },
  // {
  //   path: "/admin/investorservice/:id",
  //   element: <InvestorService />,
  // },


  {
    path: "/admin/servicesReason",
    element: <ServicesReason />,
  },
  {
    path: "/admin/servicesReason/list",
    element: <ServicesReasonList />,
  },
  {
    path: "/admin/servicesReason/:id",
    element: <ServicesReason />,
  },
  {
    path: "/admin/servicesResult",
    element: <ServicesResult />,
  },
  {
    path: "/admin/servicesResult/list",
    element: <ServicesResultList />,
  },
  {
    path: "/admin/servicesResult/:id",
    element: <ServicesResult />,
  },
  {
    path: "/admin/servicesChannel",
    element: <ServicesChannel />,
  },
  {
    path: "/admin/servicesChannel/list",
    element: <ServicesChannelList />,
  },
  {
    path: "/admin/servicesChannel/:id",
    element: <ServicesChannel />,
  },
  {
    path: "/admin/skills/:id",
    element: <Skills />,
  },
  {
    path: "/admin/skills",
    element: <Skills />,
  },
  {
    path: "/admin/skills/list",
    element: <SkillsList />,
  },
  {
    path: "/admin/occupations/:id",
    element: <Occupations />,
  },
  {
    path: "/admin/occupations",
    element: <Occupations />,
  },
  {
    path: "/admin/occupations/list",
    element: <OccupationsList />,
  },
  {
    path: "/admin/companies/:id",
    element: <Companies />,
  },
  {
    path: "/admin/companies",
    element: <Companies />,
  },
  {
    path: "/admin/companies/list",
    element: <CompaniesList />,
  },
  {
    path: "/admin/socialmedia/:id",
    element: <SocialMedia />,
  },
  {
    path: "/admin/socialmedia",
    element: <SocialMedia />,
  },
  {
    path: "/admin/socialmedia/list",
    element: <SocialMediaList />,
  },
  {
    path: "/admin/wallets/:id",
    element: <Wallets />,
  },
  {
    path: "/admin/wallets",
    element: <Wallets />,
  },
  {
    path: "/admin/wallets/list",
    element: <WalletsList />,
  },
  {
    path: "/admin/investors/:id",
    element: <Investors />,
  },
  // {
  //   path: "/admin/investors/investorDetail/:id",
  //   element: <InvestorDetail />,
  // },
  {
    path: "/admin/investors",
    element: <Investors />,
  },
  {
    path: "/admin/investors/list",
    element: <InvestorsList />,
  },
  {
    path: "/admin/investors/accountMovements/:id",
    element: <AccountMovements />,
  },
  {
    path: "/admin/investors/accountMovements",
    element: <AccountMovements />,
  },
  {
    path: "/admin/investorProfile/:id",
    element: <InvestorProfile />,
  },
  {
    path: "/admin/investorProfile",
    element: <InvestorProfile />,
  },
  {
    path: "/admin/investorProfile/list",
    element: <InvestorProfileList />,
  },
  {
    path: "/admin/investorRange/:id",
    element: <InvestorRange />,
  },
  {
    path: "/admin/investorRange",
    element: <InvestorRange />,
  },
  {
    path: "/admin/investors/investorActivesDetail",
    element: <ActivesInvestorsDetail />,
  },
  {
    path: "/admin/investorRange/list",
    element: <InvestorRangeList />,
  },
  {
    path: "/admin/investorType/:id",
    element: <InvestorType />,
  },
  {
    path: "/admin/investorType",
    element: <InvestorType />,
  },
  {
    path: "/admin/investorType/list",
    element: <InvestorTypeList />,
  },
  {
    path: "/admin/eventsTypes/:id",
    element: <EventsTypes />,
  },
  {
    path: "/admin/eventsTypes",
    element: <EventsTypes />,
  },
  {
    path: "/admin/eventsTypes/list",
    element: <EventsTypesList />,
  },
  {
    path: "/admin/investorsgroups/:id",
    element: <InvestorsGroups />,
  },
  {
    path: "/admin/investorsgroups",
    element: <InvestorsGroups />,
  },
  {
    path: "/admin/investorsgroups/list",
    element: <InvestorsGroupsList />,
  },
  {
    path: "/admin/notifications/:id",
    element: <Notifications />,
  },
  {
    path: "/admin/notifications",
    element: <Notifications />,
  },
  {
    path: "/admin/notifications/list",
    element: <NotificationsList />,
  },
  {
    path: "/admin/notificationstype/:id",
    element: <NotificationsType />,
  },
  {
    path: "/admin/notificationstype",
    element: <NotificationsType />,
  },
  {
    path: "/admin/notificationstype/list",
    element: <NotificationsTypeList />,
  },
  {
    path: "/admin/currentaccount/list",
    element: <CurrentAccountList />,
  },
  {
    path: "/admin/prospects/:id",
    element: <Prospects />,
  },
  {
    path: "/admin/prospects",
    element: <Prospects />,
  },
  {
    path: "/admin/prospects/list",
    element: <ProspectsList />,
  },
  {
    path: "/admin/newaccounts/list",
    element: <NewAccountsList />,
  },
  {
    path: "/admin/reports/advisors/investors",
    element: <AdvisorInvestorsReports />,
  },
  {
    path: "/admin/AccountsApi/:id",
    element: <AccountsApi />,
  },
  {
    path: "/admin/AccountsApi",
    element: <AccountsApi />,
  },
  {
    path: "/admin/AccountsApi/list",
    element: <AccountsApiList />,
  },
  {
    path: "/admin/clickme/dashboard",
    element: <EvaDashboard />,
  },
  {
    path: "/admin/settings",
    element: <Settings />,
  },
  {
    path: "/admin/uploadfiles",
    element: <UploadFiles />,
  },
  {
    path: "/admin/uploadfiles/list",
    element: <UploadFilesList />,
  },

  {
    path: "/admin/reportb2b/list",
    element: <Reportb2bList />,
  },
  {
    path: "/admin/investorsCategories/list",
    element: <InvestorsCategoriesList />,
  },
  {
    path: "/admin/investorsCategories",
    element: <InvestorsCategories />,
  },
  {
    path: "/admin/investorsCategories/:id",
    element: <InvestorsCategories />,
  },
  {
    path: "/admin/campaigns/list",
    element: <CampaignsList />,
  },
  {
    path: "/admin/campaigns",
    element: <Campaigns />,
  },
  {
    path: "/admin/campaigns/:id",
    element: <Campaigns />,
  },  
  {
    path: "/admin/scripts/:id",
    element: <Scripts />,
  },
  {
    path: "/admin/scripts",
    element: <Scripts />,
  },
  {
    path: "/admin/scripts/list",
    element: <ScriptsList />,
  },
  {
    path: "/admin/outlookIntegration/:id",
    element: <OutlookIntegration />,
  },
  {
    path: "/admin/outlookIntegration",
    element: <OutlookIntegration />,
  },
  {
    path: "/admin/outlookIntegration/list",
    element: <OutlookIntegrationList />,
  },
  /*
  {
    path: "/admin/investorsBullets/:id",
    element: <InvestorsBullets />,
  },
  {
    path: "/admin/investorsBullets",
    element: <InvestorsBullets />,
  },
  {
    path: "/admin/investorsBullets/list",
    element: <InvestorsBulletsList />,
  },
  */
];


export const GetUsersRoutes = () => {
 var item = localStorage.getItem("menus");
  var menu = item && JSON.parse(item);

  var menuchildren = menu&& menu[0].children;
  
menu &&  menu.concat(menuchildren);
  
  var b: any[] = [];
  var d = [...adminPagesConfigs];
  var a = adminPagesConfigs;


  menu && menu.map((menuItem) => {
    const j = a.findIndex(item => item.path.toLowerCase() === menuItem.url.toLowerCase());
    if(j > -1) {
      var c = a.find((o) => {return o.path.toLowerCase() === menuItem.url.toLowerCase()});
      b.push(c);
    }
    // b.push(c);
    // a.splice(j, 1);
  })
    //  adminPagesConfigs = adminPagesConfigs.filter((item) => b.includes(item));
  d = d.filter((item) => !b.includes(item));

  // console.log(adminPagesConfigs, "adminPAges");
  //  return d;
    return adminPagesConfigs;
}
// export const adminPagesConfigs2 = GetUsersRoutes();
export const adminNonPagesConfig = GetUsersRoutes();


