import axios from "axios";


const jwtAxios = axios.create({

  // baseURL: 'https://evadata.evamind.com.br/api',
  baseURL: process.env.REACT_APP_EVADATA_URL,
  //baseURL: 'https://api.clickme-app.com/api', //YOUR_API_URL HERE
  headers: {
    'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      delete jwtAxios.defaults.headers.common.Authorization;
      localStorage.removeItem("token");
      localStorage.removeItem("tenantId");
      //localStorage.removeItem("profileId");

      setTimeout(() => {
        window.location.href = "/signin";
      }, 1800);
    }
    if (err.response && err.response.data.msg === "Token is not valid") {
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);



jwtAxios.interceptors.request.use((config) => {    
  config.data = config.data || {};
  // if (localStorage.getItem("profileId") != null) {
  //   config.data['profileId'] = localStorage.getItem("profileId");
  // }
  if (localStorage.getItem("tenantId") != null) {
    config.data['tenantId'] = localStorage.getItem("tenantId");
  }
  return config;
});
export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem("token");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("menus");
    //localStorage.removeItem("profileId");
  }
};



export default jwtAxios;


