import * as React from 'react';
import { DataGrid, GridRowsProp,GridSelectionModel,GridColDef,GridFilterModel,GridRowId } from '@mui/x-data-grid';
import { GridDemoData, useDemoData } from '@mui/x-data-grid-generator';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, alpha, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import IntlMessages from '@crema/utility/IntlMessages';

interface TableProps{
  rowHead:any[],
  rowData:any[],
  processData?: boolean,  
  buttonName: string,  
  buttonFunction,
      
}

function loadServerRows(page: number, data: GridDemoData): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data.rows.slice(page * 5, (page + 1) * 5));
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export default function ControlledSelectionServerPaginationGrid(props : TableProps) {
 
  const { rowHead, rowData, processData, buttonName, buttonFunction, ...rest} = props;

  const theme = useTheme();  
  const intl = useIntl();

  const [pageSize, setPageSize] = useState(25);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<GridRowId [] | undefined>([]); 


  useEffect(() => {
   if(rowData.length > 0 || processData === true){
       setLoading(false);
   }
   else{
     setLoading(true);
   }
 }, [rowData]) 
  
 
  const header: GridColDef[] = [];
    const id = {field: 'id', headerName: 'ID', hide: true };
    header.push(id);
    

  rowHead.forEach(element => {
    const columnsNew: GridColDef = {
        field: element.Field, 
        headerName: element.isNotTranslate ? element.id : intl.formatMessage({ id: element.id }),               
        headerAlign: element.align  ? element.align  : "left" ,         
        width: element.minWidth, 
        hide: (!element.show),
        filterable: element.isSearch,            
        align: element.align  ? element.align  : "left" ,  
        sortable: element.isActions ? false : true,
        disableColumnMenu: element.isActions ? true : false,      
    }
header.push(columnsNew);
});



var newData = rowData.map(item => {
    const newItem = {};
    header.forEach(column => {
      const fieldName = column.field;
      newItem[fieldName] = item[fieldName];
    });
    return newItem;
  });
  
//const keyItem = rowHead.find(item => item.isKey === true);
const keyItem = rowHead.find((item: { Field: string; isKey: boolean }) => item.isKey === true);
const key = keyItem ? keyItem.Field : undefined;


newData = newData.map((item) => {        
return {
    ...item,
    id: item[key] 
};
});


newData = newData.map((item: { [key: string]: any }) => {
const { [keyItem.Field]: _, ...rest } = item;
return rest;
});

const newHeader = header.filter(item => item.field !== key);   


const localizedTextsMap = {
  //noRowsLabel: 'Sem linhas.',
  noRowsLabel: intl.formatMessage({ id: 'datagrid.noRowsLabel' }),
  noResultsOverlayLabel: intl.formatMessage({ id: 'datagrid.noResultsOverlayLabel' }),
  errorOverlayDefaultLabel: intl.formatMessage({ id: 'datagrid.errorOverlayDefaultLabel' }),
  filterPanelColumns: intl.formatMessage({ id: 'datagrid.filterPanelColumns' }),
  filterPanelOperators: intl.formatMessage({ id: 'datagrid.filterPanelOperators' }),
  filterPanelInputPlaceholder: intl.formatMessage({ id: 'datagrid.filterPanelInputPlaceholder' }),
  columnMenuUnsort: intl.formatMessage({ id: 'datagrid.columnMenuUnsort' }),
  columnMenuSortAsc: intl.formatMessage({ id: 'datagrid.columnMenuSortAsc' }),
  columnMenuSortDesc: intl.formatMessage({ id: 'datagrid.columnMenuSortDesc' }),
  columnMenuFilter: intl.formatMessage({ id: 'datagrid.columnMenuFilter' }),
  columnMenuHideColumn: intl.formatMessage({ id: 'datagrid.columnMenuHideColumn' }),
  columnMenuShowColumns: intl.formatMessage({ id: 'datagrid.columnMenuShowColumns' }),
  filterOperatorContains: intl.formatMessage({ id: 'datagrid.filterOperatorContains' }),
  filterOperatorEquals: intl.formatMessage({ id: 'datagrid.filterOperatorEquals' }),
  filterOperatorStartsWith: intl.formatMessage({ id: 'datagrid.filterOperatorStartsWith' }),
  filterOperatorEndsWith: intl.formatMessage({ id: 'datagrid.filterOperatorEndsWith' }),
  //filterOperatorIs: intl.formatMessage({ id: 'datagrid.filterOperatorIs' }),
  //filterOperatorNot: intl.formatMessage({ id: 'datagrid.filterOperatorNot' }),
  //filterOperatorAfter: intl.formatMessage({ id: 'datagrid.filterOperatorAfter' }),
  //filterOperatorOnOrAfter: intl.formatMessage({ id: 'datagrid.filterOperatorOnOrAfter' }),
  //filterOperatorBefore: intl.formatMessage({ id: 'datagrid.filterOperatorBefore' }),
  //filterOperatorOnOrBefore: intl.formatMessage({ id: 'datagrid.filterOperatorOnOrBefore' }),
  filterOperatorIsEmpty: intl.formatMessage({ id: 'datagrid.filterOperatorIsEmpty' }),
  filterOperatorIsNotEmpty: intl.formatMessage({ id: 'datagrid.filterOperatorIsNotEmpty' }),
  filterOperatorIsAnyOf: intl.formatMessage({ id: 'datagrid.filterOperatorIsAnyOf' }),
  columnsPanelTextFieldLabel: intl.formatMessage({ id: 'datagrid.columnsPanelTextFieldLabel' }),
  columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'datagrid.columnsPanelTextFieldPlaceholder' }),
  columnsPanelDragIconLabel: intl.formatMessage({ id: 'datagrid.columnsPanelDragIconLabel' }),
  columnsPanelShowAllButton: intl.formatMessage({ id: 'datagrid.columnsPanelShowAllButton' }),
  columnsPanelHideAllButton: intl.formatMessage({ id: 'datagrid.columnsPanelHideAllButton' }),
};

const handleClickButton = () => {

  buttonFunction(selectedIDs);

}

  return (
    <Container  sx={{ mt: 1 }}>
      <Grid item xs={12} style={{ height: 400, width: '100%' }} sx={{ display: "flex", justifyContent: "center" }}>
      <DataGrid
        aria-label="sticky table"
        rows={newData}
        columns={newHeader}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        disableSelectionOnClick
        localeText={localizedTextsMap}
        loading={loading}
        checkboxSelection     
        onSelectionModelChange={(ids) => {

          const selectedIDsNew = new Set(ids);
            const convertedSet: GridRowId [] | undefined = selectedIDsNew.size > 0 ? [...selectedIDsNew] : undefined;
            setSelectedIDs(convertedSet);
        }}
          componentsProps={{
            panel: {
              sx: {
                '& .MuiDataGrid-columnsPanelRow:first-of-type': {
                  display: 'none', 
                },               
              },
            },
        }}
        sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                width: 8,
                height: 8
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                backgroundColor: "primary.main",          
                borderRadius: 2,
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                backgroundColor: "#757575",         
                borderRadius: 2
            },
            '.MuiDataGrid-columnSeparator': {
                display: 'none',
            },
            '&.MuiDataGrid-root': {
                border: 'none',
            },
            //'& .MuiDataGrid-cell:hover': {
            //    color: 'primary.main',
            //},
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main ,
                fontSize: 14,
                fontWeight: 700,
                fontfamily:'Poppins, serif',
                //fontWeight: Fonts.BOLD,
                // Forced to use important since overriding inline styles
                maxHeight: "168px !important",
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: "normal",
                lineHeight: "normal"
            },
            '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
                height: "unset !important"
            },        
            '& .MuiDataGrid-cell': {
            // Forced to use important since overriding inline styles
               maxWidth: "300px !important"
            },        
          }}        
      />
      </Grid>
      <Grid item xs={12} 
        sx={{ 
            display: "flex", 
            //justifyContent: "center" 
        }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              minWidth: 60,
              //mt: 2, // Ajuste a margem superior para posicionar o botão corretamente
              m: 3
              //align: 'center',
            }}
            color="primary"
            variant="contained"
            type="button"
            onClick={handleClickButton}
          >
            <IntlMessages id={buttonName} />
          </Button>
        </Box>
      </Grid>
    </Container>
  );
}
