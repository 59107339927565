import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { meetingsPainelData } from "../mock";
import AppGridContainer from '@crema/core/AppGridContainer';
import { AppCard, AppLoader } from '@crema';
import { useIntl } from "react-intl";
import { useAuthUser } from "@crema/utility/AuthHooks";
import jwtAxios from '@crema/services/auth/jwt-auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import InvestorsList from './list';
import { CategoryOutlined } from '@mui/icons-material';
import moment from "moment"

const ManageClientsDashboard: React.FC = () => {

    return (
        <InvestorsList />
    )
};
export default ManageClientsDashboard;