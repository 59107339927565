import Settings from "./Setting";
import Common from "./Common";
import Filter from "./Filter";

const reducers = {
  settings: Settings,
  common: Common,
  filter: Filter
};

export default reducers;
