import AppAnimate from '@crema/core/AppAnimate';
import AppCard from '@crema/core/AppCard';
import React from 'react';

// import { Container } from './styles';

const Commission: React.FC = () => {
  const token = localStorage.getItem("token");
  const url = `https://comissionamento.splitc.com.br/sso?client_id=4f554d28-b139-40eb-9bfe-30bf6e67396e&token=${token}`;

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <AppCard>
        <iframe src={url} style={{ width: "100%", height: "85vh", borderRadius: "10px" }} />
      </AppCard>
    </AppAnimate>
  );
}

export default Commission;