import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { Button, Container, Grid } from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import { useAuthUser } from "@crema/utility/AuthHooks";
import * as base from "../base";
import ControlledSelectionServerPaginationGrid from '../DataTableControlledSelection';
import { fetchError, fetchStart, fetchSuccess, showMessage } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

interface Advisor {
    Name: string;
    Surname: string;
    UserId: string;
    AccessorCode: string;
  }

interface TableProps{
  dataParam:any,
  functionCloseChield, 
  urlId?,     
}

interface Data{
  Id: string,
  Name: string,

}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const MultipleSelectEva = (props : TableProps) => {
  const { dataParam, functionCloseChield,urlId, ...rest} = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useAuthUser();
  
  var walletId = "";

  if(urlId !== "" && urlId !== undefined){
    walletId = urlId.id;;
  }
  else{
    var gId = base.GetIdUrl();
     walletId = gId ? gId[0] : "";
  }
  

  const [personName, setPersonName] = React.useState<string[]>([]);
  const [processData, setProcessData] = useState(false);
 
  const url = dataParam.methodApi;
  const urlApi = dataParam.urlApi;
  const urlFilter = dataParam.selectConfig.columnResultMethodApi;  
  const header = dataParam.selectConfig.gridColumns;
  

  const columnResultId = dataParam.selectConfig.columnResultId;
  const [dataAssociate, setDataAssociate] = useState<Data[]>([]);
  const [columnResultData, setColumnResultData] = useState<Data[]>([]);
  const [rowData, setrowData] = useState<Data[]>([]);

  

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {target: { value },} = event;
    
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };




  const handleClickButton = async (selectId) => {
    
     
    functionCloseChield(); 
     if(selectId !== "")
    {       
      dispatch(fetchStart()); 
        const jsonSend ={walletId: walletId, ids: selectId}
        await jwtAxios.post(urlApi + "/RegisterRangeId", jsonSend).then((res) => {    

                   
        })
        dispatch(fetchSuccess());         
        //setTimeout(() => {}, 1800);           
        dispatch(showMessage('Sucess.')) 
        
        
    }
  };

  

  useEffect(() => {

    var paramFilter = {};

  if(urlId !== "" && urlId !== undefined){
    paramFilter = urlId;
  }
  else{
    var gId = base.GetIdUrl();
    paramFilter = {id: gId ? gId[0] : ""};
  }

    //var gId = base.GetIdUrl();
    //var paramFilter = {id: gId && gId[0]};

    jwtAxios.post(urlApi + '/' + url, paramFilter).then((res) => {        
      var data = res.data.List;      
      var dataProcessed: Data[] = [];
      for ( const item in data){  
        
        var newItem:Data = {
          Id: data[item][columnResultId].toString(),
          //Name: data[item].Name.toString() + ' ' + data[item].Surname !== null ? data[item].Surname.toString() : "",
          Name: data[item].Name.toString() + ' ' + (data[item].Surname !== null && data[item].Surname !== undefined ? data[item].Surname.toString() : "")
        };
        dataProcessed.push(newItem);
      }   
      setDataAssociate(dataProcessed)            
    })
    if(urlApi !== "WalletsInvestors" && urlApi !== "InvestorsGroups"){
      jwtAxios.post(urlFilter, { ParamSearch: { isActive: true } }).then((res) => {    
             
        var data = res.data.List;
        
        var dataProcessed: Data[] = [];
        for ( const item in data){  
          var newItem:Data = {
            Id: data[item][columnResultId].toString(),
            //Name: data[item].Name.toString() + ' ' + data[item].Surname !== "null" ? data[item].Surname.toString() : "",
            Name: data[item].Name.toString() + ' ' + (data[item].Surname !== null && data[item].Surname !== undefined ? data[item].Surname.toString() : ""),

          };
          
          dataProcessed.push(newItem);
        }   
        setColumnResultData(dataProcessed);
      })
    }
    else
    {
      jwtAxios.post(urlFilter, paramFilter).then((res) => {            
        var data = res.data;
        var dataProcessed: Data[] = [];
        for ( const item in data){  
          var newItem:Data = {
            Id: data[item][columnResultId].toString(),
            Name: data[item].Name.toString(),
          };
          dataProcessed.push(newItem);
        }   
        setColumnResultData(dataProcessed);
      })
    }
  },
  [])

  useEffect(() => {

    const dataAssociateIds = new Set(dataAssociate.map(item => item.Id));
    const columnResultDataNew = columnResultData.filter(item => !dataAssociateIds.has(item.Id));
    setrowData(columnResultDataNew);

  },
  [columnResultData])




  return (
    <Container maxWidth="md" sx={{ mt: 1 }}>
       <Grid item xs={12}>
                <Grid item xs={12}>
                    <IntlMessages id={dataParam.selectConfig.columnResultTitle} /> 
                </Grid>
                
                  <Grid item xs={12}>
                   
                      <ControlledSelectionServerPaginationGrid 
                      {...{ 
                        rowData: rowData,
                        rowHead: header,
                        processData: processData,
                        buttonName: "common.add",
                        buttonFunction: handleClickButton                        
                      }}
                      />
                       
                  </Grid>
                
            </Grid>
    </Container>
  
  );
}

export default MultipleSelectEva;
