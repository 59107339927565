import React, { ChangeEventHandler, useEffect, useState } from "react";
import IntlMessages from "@crema/utility/IntlMessages";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Field, FieldArray, Form, Formik, FormikContext, useFormik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import AppGridContainer from "@crema/core/AppGridContainer";
import Grid from "@mui/material/Grid";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import { styled, useTheme } from "@mui/material/styles";
import { LabelObj, PriorityObj, StaffObj } from "types/models/Todo";
import { Fonts } from "shared/constants/AppEnums";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { DateTimePicker, DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { AutoLink, Link } from '@ckeditor/ckeditor5-link';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox, FormControlLabel, OutlinedInput, Theme, Typography } from "@mui/material";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchStart, fetchSuccess, showMessage, fetchError } from "redux/actions";
import moment from "moment";
import AppInfoView from "@crema/core/AppInfoView";
import { uuid } from 'uuidv4';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { hideMessage } from "../../../redux/actions";
import { IInteractionType } from "../investorService";
import { data } from "../investorgroups/params";
import { render } from "react-dom";
import * as base from "../../../shared/basePages/base";
import { act } from "react-dom/test-utils";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { number } from "prop-types";
import { AppState } from "redux/store";


export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: 20,
  marginBottom: 20,
  [theme.breakpoints.up("xl")]: {
    marginTop: 32,
    marginBottom: 32,
  },
}));

interface AddTaskFormProps {
  values: any;
  isSubmitting: boolean;

  //   taskLabels: LabelObj[];
}
interface ClientsData {
  userId: string;
  name: string;
}

interface EventsData {
  eventId: string;
  scheduleId: string;
  userId: string;
  eventTypeId: string;
  investorId: string;
  title: string;
  description: string;
  phoneNumber: string;
  email: string;
  // place: string;
  Notifications: Date[];
  dtStart: Date;
  dtEnd: Date;
  whats: string;
  site: string;
  imagem: string;
  street: string;
  interactionTypeId:string
}
interface EventsTypesData {
  EventTypeId: string;
  Name: string;
}

interface InvestorData {
  InvestorId: string;
  Name: string;
  Surname: string;
  AccountNumber: string;
}

interface NotificationsData {
  // NotificationsId: string;
  // NotificationsTypeId: string;
  // NotificationsTypeName: string;
  // UserId: string;
  // EventId: string;
  // IsRead: boolean;
  // NotifyByEmail: boolean;
  // NotifyBySMS: boolean;
  // Title: string;
  // Description: string;
  // EventDate: string;
  AlertTime: string;
}
interface EventProps {
  selectedEvent: any;
  fuctionSelected: any;
  functionCloseChield: any;
}

var deletado=false;

// const today = dayjs();
// const yesterday = dayjs().subtract(1, 'day');
// const todayStartOfTheDay = today.startOf('day');
// const tomorrow = dayjs().add(1, 'day');



const AddTaskForm: React.FC<EventProps> = ({ selectedEvent,fuctionSelected, functionCloseChield }) => {  
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  
  const maintenanceTypes = [{label: "periodicContact"},{label: "onlineWalletReview"},{label: "inPersonWalletReview"}]
  const navigate = useNavigate();
  const [eventsTypes, setEventsTypes] = useState<EventsTypesData[]>([]);
  const [notificationsData, setNotificationsData] = useState<NotificationsData[]>([]);
  const [events, setEvents] = useState<EventsData[]>([]);

  const [title, setTitle] = React.useState<string>("");
  const { filtro, vision } = useSelector<AppState, AppState["filter"]>(({ filter }) => filter);  
  const [isDisabled, setIsDisabled] = useState<boolean>(selectedEvent.eventId == undefined ? false : true);
  const [isUpdate, setIsUpdate] = useState<boolean>(selectedEvent.eventId == undefined ? true : false);
  
  const [clients, setClients] = useState<ClientsData[]>([]);
  const [optionalVisibility, setOptionalVisibility] = useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [descriptionData, setDescription] = React.useState<string>("");
  const [eventType, setEventType] = React.useState<string>("");
 
  const [interactionTypeId, setInteractionTypeId] = useState("");
  
  const [customrerMaintenance, setCustomrerMaintenance] = useState(false);
  const [interactionType, setInteractionType] = useState<IInteractionType[]>([]);
  
  
  const [investorId, setInvestorId] = React.useState<string>(); 

  const [investorName, setInvestorName] = React.useState<string>();  
  const [investorData, setInvestorData] = React.useState<InvestorData[]>();
  const [hourStart, setHourStart] = React.useState<dayjs.Dayjs | null>(
    selectedEvent?.start !== undefined ? dayjs(selectedEvent?.start) : null
  );
  const [hourEnd, setHourEnd] = React.useState<dayjs.Dayjs | null>(
    selectedEvent?.end !== undefined ? dayjs(selectedEvent?.end) : null
  );
  
  
  // valter 04/10/2023
  const [horaFimError, setHoraError] = useState(false);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { user, scheduleId } = useAuthUser();
  const userId = filtro !== "" ? filtro : user?.userId;

  const validationSchema = yup.object({
    title: yup.string().required(String(messages["validation.titleRequired"])),
  });


  const handleInvestorChange =  (event) => {   
    //setInvestorName(event.target.value as string);  
    setInvestorId(event.target.value as string);
  };

  const handleEventTypeChange =  (event) => {
    setEventType(event.target.value as string);     
    validationEventType(event.target.value);   
    var selectedItem  = eventsTypes.find(item => item.EventTypeId === event.target.value)
    if(selectedItem?.Name === "customrerMaintenance"){
      setCustomrerMaintenance (true); 
     }
    else{
      setCustomrerMaintenance (false);
      setInteractionTypeId("00000000-0000-0000-0000-000000000000");
     }

  };

  const validationEventType = (id) =>{
    var selectedItem  = eventsTypes.find(item => item.EventTypeId === id)
    if(selectedItem?.Name === "customrerMaintenance"){
      setCustomrerMaintenance (true); 
    }
    else{
     setCustomrerMaintenance (false);
    }
  }
  
  const handleDtEndChange = (newValue: Date | null) => {
      setEndDate(newValue);
  };

 const handleDtStartChange = (newValue: Date | null) => {
   setStartDate(newValue);   
    setEndDate(newValue);      
 };

  const handleHourStartChange = (newValue: dayjs.Dayjs | null) => {    
    setHourStart(newValue);
  };

  const handleHourEndChange = (newValue: dayjs.Dayjs | null) => {    
    setHourEnd(newValue);
    setHoraError(false);
  };

  const handleDescriptionChange = (newValue: string) => {
    setDescription(newValue);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const isSameOrBefore = (startTime, endTime) => {    
    return moment(startTime, 'HH:mm').isSameOrBefore(moment(endTime, 'HH:mm'));
  }

  const handleInteractionTypeIdChange = async (event) => {        
    setInteractionTypeId(event.target.value);
  }

  const handleEditEvento = async() => {
    setTimeout(() => 
    {
      setIsDisabled(false);
      setIsUpdate(true);
    }
   , 2);  
    
  }

  

  const onClose = (mensagem) => {
    if (deletado){
      mensagem = "evento excluido com sucesso!"
      deletado = false;
    }
    functionCloseChield();
    setTimeout(() => 
    {
      fuctionSelected(false);
      dispatch(showMessage(mensagem));
    }
   , 2000);   
  };

// função criada em 27/09/23, pelo Valter, para excluir o evento criado na agenda, ele marca na tabela de Events o campo [IsDeleted] = 1
  const handleRemoveEvento= () => {
      deletado = true;
      base.ActionsFunction(navigate, "delete", selectedEvent.eventId, onClose)
  }
  
  useEffect(() => {    
    jwtAxios.post("eventstypes/getbyfilter", { ParamSearch: { isActive: true } }).then((response) => {      
      setEventsTypes(response.data.List);
    })
    if (selectedEvent.eventId) {  
      fetchNotifications(selectedEvent.eventId)
    }

    base.SetUrlApi("events");
    
    jwtAxios.get("investors/GetAllInvestorsByUserId/" + userId  + "/" + ((vision === "" || vision === undefined)? "see_one": vision )).then((response) => {
      var investorList: InvestorData[] = [];
      response.data.map((investor) => {
        if(investor.InvestorId === selectedEvent.investorId) {
          setInvestorName(investor.Name);
        }
          investorList.push({
            InvestorId: investor.InvestorId,
            Name: investor.Name,
            Surname: investor.Surname,
            AccountNumber: investor.AccountNumber
          })
      })

      setInvestorData(investorList);
    })

    jwtAxios.post("users/getbyfilter", { ParamSearch: { isActive: true } }).then((response) => {
      let accessorsArray: any[] = [];
      response.data.List.map((accessor) => {
        if (accessor.UserId !== user?.userId) {
          accessorsArray.push({ userId: accessor?.UserId, name: accessor?.Name + " " + accessor?.Surname });
        }
      })
      setClients(accessorsArray);
    })

    jwtAxios.post("interactionType/getByFilter", { ParamSearch: { IsActive: true, ServicesReasonId: "" } }).then((response) => {            
      setInteractionType(response.data.List);
    })
   
    
    setInteractionTypeId(selectedEvent.interactionTypeId);

    dispatch(fetchSuccess())
  }, [])

  useEffect(()=>{

    validationEventType(selectedEvent.eventTypeId);    

  },[eventsTypes])

  useEffect(()=>{    
    if (startDate !== null && endDate !== null && endDate < startDate)
    {
      setEndDate(endDate)
    }
  },[startDate])

  const fetchNotifications = (eventId: string) => {
    jwtAxios.post("notifications/getbyfilter", { ParamSearch: { EventId: eventId } }).then((response) => {      
      setNotificationsData(response.data.List.map(notification => notification.AlertTime));
    })
  }

  const validateForm = (values) => {
    const errors = {};      

    if (customrerMaintenance && (values["interactionTypeId"]==="" || values["interactionTypeId"]=== undefined  || values["interactionTypeId"]==="00000000-0000-0000-0000-000000000000") ) {
      errors["interactionTypeId"] = 'Obrigatório selecionar Tipo';
    } 
    if (customrerMaintenance && (values["investorId"]==="" || values["investorId"] ==="00000000-0000-0000-0000-000000000000")) {
      errors["investorId"] = 'Obrigatório selecionar um Investidos';
    }
      // constante que valida campos no formulário, alteração feita em 03/10/23, incluindo a validação da hora final > hora inicial para data iguais.
    if (values.dtEnd.getDate() === values.dtStart.getDate()) 
    {
      if(dayjs(values.hourEnd).format("HH:mm:ss") < dayjs(values.hourStart).format("HH:mm:ss"))
      {

        errors["hourEnd"] = 'Hora Final, precisa ser igual ou maior que hora Final';
        setHoraError(true);
        
      } 
      else 
      {

        setHoraError(false);
      }
      
    }
      

    return errors;
  };

  return (
    <Formik
      validateOnChange={true}      
      enableReinitialize     
      initialValues={{
        eventId: selectedEvent?.eventId ? selectedEvent?.eventId : "",
        scheduleId: scheduleId,
        notifications: notificationsData ? notificationsData : [],
        userId: selectedEvent?.userId ? selectedEvent?.userId : "",
        title: selectedEvent?.title ? selectedEvent?.title : title,
        description: selectedEvent?.desc ? selectedEvent?.desc : "",
        phoneNumber: selectedEvent?.phoneNumber ? selectedEvent?.phoneNumber : "",
        email: selectedEvent?.email ? selectedEvent?.email : "",
        // place: selectedEvent?.place ? selectedEvent?.place : "",
//         interactionTypeId: selectedEvent?.interactionTypeId ? selectedEvent?.interactionTypeId : "",
//------------------------------------------------------------------
        investorId: selectedEvent?.investorId ? selectedEvent?.investorId : investorId,
        eventTypeId: selectedEvent?.eventTypeId ? selectedEvent?.eventTypeId : "",
        interactionTypeId: selectedEvent?.interactionTypeId ? selectedEvent?.interactionTypeId : interactionTypeId,
//------------------------------------------------------------------
        dtStart: startDate === null ? selectedEvent?.start : startDate,
        dtEnd: endDate === null ? selectedEvent?.end : endDate,
        // hourStart: startDate === null ? selectedEvent?.hourStart : startDate,
        // hourEnd: endDate === null ? selectedEvent?.hourEnd : endDate,
        //valter 04/10/2023
        hourStart: hourStart === null ? selectedEvent?.hourStart : hourStart,
        hourEnd: hourEnd === null ? selectedEvent?.hourEnd : hourEnd,       
        //typeCustomerMaintenance: selectedEvent?.interactionTypeId ? selectedEvent?.interactionTypeId : "",
        idEventOutlook: selectedEvent?.idEventOutlook ? selectedEvent?.idEventOutlook : ""
      }}
      validationSchema={validationSchema}
      validate={validateForm}
      onSubmit={(data, { setSubmitting, resetForm  }) => {
        dispatch(fetchStart());
       
        var month = data.dtStart.getMonth() + 1;
        var year = data.dtStart.getFullYear();
        var day = data.dtStart.getDate();
       
        let hourStartDate = dayjs(hourStart).format("HH:mm:ss");
        let hourEndDate = dayjs(hourEnd).format("HH:mm:ss");
        
        

        if (day < 10) day = "0" + day;

        var formattedStartDate = year + "-" + month + "-" + day + " " + hourStartDate;

        var endMonth = data.dtEnd.getMonth() + 1;
        var endYear = data.dtEnd.getFullYear();
        var endDay = data.dtEnd.getDate();

        if (endDay < 10) endDay = "0" + endDay;

        var formattedEndDate = endYear + "-" + endMonth + "-" + endDay + " " + hourEndDate;
        

        setSubmitting(true);
        const newTask = {
          EventId: data.eventId !== null ? data.eventId : "",
          ScheduleId: scheduleId,
          //UserId: user?.userId,
          UserId: userId,
          PhoneNumber: data.phoneNumber,
          Email: data.email,
          InvestorId: data.investorId,
          // Place: data.place,
          Title: data.title,
          Description: descriptionData === "" ? data.description : descriptionData,
          // clients: clients,
          // dtStart: addHours(8,data.dtStart),
          // dtEnd: addHours(8, data.dtEnd),
          DtStart: formattedStartDate,
          DtEnd: formattedEndDate,
          HourStart: hourStartDate,
          HourEnd: hourEndDate,
          //EventTypeId: data.eventTypeId,
          EventTypeId: eventType ==="" ? data.eventTypeId : eventType,          
          interactionTypeId: interactionTypeId === "" ? data.interactionTypeId : interactionTypeId,          
          IsActive: true,
          IdEventOutlook : data.idEventOutlook
        };
        
        if (newTask.EventId === "") {          
          jwtAxios.post("events/register", newTask).then((res) => {
            if (res.status === 200) {
              if (data.notifications.length === 0) {
                jwtAxios.post("notifications/register", {
                  Title: res.data.Title,
                  Description: res.data.Title,
                  EventDate: res.data.DtStart,
                  AlertTime: res.data.DtStart,
                  EventId: res.data.EventId,
                  IsActive: true,
                  IsRead: false,
                  UserId: res.data.UserId,
                  NotificationsTypeId: res.data.NotificationTypeId,
                  NotificationsId: uuid()
                }).then((res) => {
                  if (res.status === 200) {
                   dispatch(fetchSuccess)
                   // dispatch(showMessage("evento cadastrado com sucesso!"))
                    fuctionSelected(false)
                    onClose("evento cadastrado com sucesso!")
                  }
                })
              }
              else {
                
                data.notifications.map((notification: any) => {
                  notification.setHours(notification.getHours() - 3);

                  jwtAxios.post("notifications/register", {
                    Title: data.title,
                    Description: res.data.Description,
                    EventDate: data.dtStart,
                    AlertTime: notification.toISOString(),
                    IsActive: true,
                    IsRead: false,
                    EventId: res.data.EventId,
                    //UserId: user?.userId,
                    UserId: userId,
                    NotificationsTypeId: data.eventTypeId,
                    NotificationsId: uuid()
                    
                  }).then((res) => {
                    if (res.status === 200) {
                      dispatch(fetchSuccess)
                      //dispatch(showMessage("evento cadastrado com sucesso!"))
                      onClose("evento cadastrado com sucesso!")
                     
                     
                    }
                  })
                })
              }
            }
          })
        }
        else {            
          //if(user?.userId === selectedEvent?.userId ){
          if(userId === selectedEvent?.userId ){
            jwtAxios.post("events/update", newTask).then((res) => {
              if (res.status === 200) {
                if(data.notifications.length > 0) {                  
                }
              }              
              dispatch(fetchSuccess)             
              onClose("evento atualizado com sucesso!");              
            })
          }
          else{
            dispatch(fetchError("Evento só pode ser alterado pelo próprio Assessor!"));
            onClose("Evento só pode ser alterado pelo próprio Assessor!");
            //setSubmitting(false);     
          }
        }        
        resetForm();
        setSubmitting(false);
        
        //fuctionSelected(false)
      }}
    >
      {({ isSubmitting, values, errors, touched,setFieldValue  }) => (
        <Form
           style={{
            width: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <div>            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/*<AppTextField
                sx={{
                  width: "100%",
                  fontWeight: Fonts.LIGHT,
                  marginBottom: 5,
                }}
                variant="outlined"
                label={<IntlMessages id="titulo" />}
                placeholder="teste"
                value={values.title}
               
                onChange={(e) => {
                  setFieldValue('title',e.target.value);
                  handleTitleChange(e);
                  
                }}
                //onChange={handleTitleChange}
                
                name="title"
              />
               */}
              <Box mb={5}>
                <TextField
                    value={values.title}
                    onChange={handleTitleChange}
                    variant="outlined"
                    name="title"
                    fullWidth
                    disabled={isDisabled}     
                    label={<IntlMessages id={`common.title`} />}
                    error={!!errors['title'] } // Verifique se há erro
                    helperText={!!errors['title'] && <IntlMessages id={"requiredfield"} />}
                />
              </Box>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                  <DesktopDatePicker
                    disablePast
                    label="Data de Início"
                    inputFormat="dd/MM/yyyy"
                    value={values?.dtStart}
                    //value={startDate === null ? selectedEvent?.dtStart: startDate}
                    onChange={handleDtStartChange}
                    disabled={isDisabled}     
                    renderInput={(params) => <TextField {...params}
                      name="dtStart"
                      style={{
                        width: "100%",
                        fontWeight: Fonts.LIGHT,
                        marginBottom: 3,
                      }} />}
                  />
                </Grid>
                <Grid item xs={3} >
                  <TimePicker
                    label="Hora de Início"
                    value={hourStart === null ? selectedEvent?.hourStart: hourStart} 
                    disabled={isDisabled}                                  
                    ampm={false}
                    onChange={(e) => {                         
                       setFieldValue('hourStart',dayjs(e).format("HH:mm:ss"));      
                       setFieldValue('hourEnd',hourEnd);     
                       handleHourStartChange(e);
                      }
                    }
                    renderInput={(params) => <TextField {...params}
                      name="hourStart"
                      style={{                        
                        width: "100%",
                        fontWeight: Fonts.LIGHT,
                        marginBottom: 3,
                      }} />}
                  />
                </Grid>
              </Grid>
              {/* <Box mb={5}>
                <AppTextField
                  sx={{
                    width: "80%",
                    fontWeight: Fonts.LIGHT,
                    marginBottom: 3,
                  }}
                  variant="outlined"
                  label={<IntlMessages id="Participantes" />}
                  name="participants"
                />
                <FormControlLabel control={<Checkbox onClick={() => setOptionalVisibility(!optionalVisibility)} />} label="Opcionais" style={{ padding: 10 }} />
              </Box> */}
              <Box mb={5}>
                <AppTextField
                  className={optionalVisibility ? "visible" : "notVisible"}
                  sx={{
                    width: "100% !important",
                    fontWeight: Fonts.LIGHT,
                    marginBottom: 3,
                  }}
                  disabled={isDisabled}  
                  variant="outlined"
                  label={<IntlMessages id="Participantes Opcionais" />}
                  name="optionalParticipants"
                />
              </Box>
              {/* <Box mb={5}>
                {clients.map((item) => console.log(item.userId, "item"))}
              <FormControl sx={{ m: 1, width: "100%"}}>
                    <InputLabel id="demo-multiple-chip-label">Clientes</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      //value
                      value={clients.map((item) => item.name)}

                      onChange={handleMultiChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => {
                        console.log(selected, "selected");
                        
                        return(
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                        )
                      }}
                      MenuProps={MenuProps}
                    >
                      {clients.map((client) => (
                        <MenuItem
                          key={client.userId}
                          value={client.userId}
                          style={getStyles("name", personName, theme)}
                        >
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Box> */}
              <Grid container item xs={12} spacing={2}>
             <Grid item xs={6} sm={6} md={6}>
                    <FormControl
                      variant="outlined"
                      sx={{
                        width: "100%",
                        marginBottom: 5
                      }}
                    >
                      <TextField
                        name="EventTypeId" 
                        value={eventType === "" ? selectedEvent?.eventTypeId : eventType}
                        id="EventType"
                        label={<IntlMessages id="Evento" />}
                        select
                        disabled={isDisabled}  
                        onChange={(e) => {
                          setFieldValue('eventTypeId',e.target.value);
                          if(!customrerMaintenance){
                           setFieldValue('interactionTypeId',"00000000-0000-0000-0000-000000000000")
                          }
                          handleEventTypeChange(e);
                        }}
                      >
                        <MenuItem value={selectedEvent?.eventTypeId ? selectedEvent?.eventTypeId: ""} disabled>Selecione um valor</MenuItem>
                        {eventsTypes?.map((event) =>
                        (
                          <MenuItem
                          style={{display: "block", padding: "5px"}}
                            value={event.EventTypeId}
                            key={event.EventTypeId}
                            sx={{
                              cursor: "pointer",                            
                            }}                            
                          >
                            <Typography><IntlMessages id={event.Name.toLowerCase()} /></Typography>
                            {/* {messages["schedule."+event.Name.toLowerCase()]} */}
                          </MenuItem>
                        )
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  {customrerMaintenance &&
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl
                      variant="outlined"
                      sx={{
                        width: "100%",
                        marginBottom: 5
                      }}
                    >
                      <TextField
                        label={<IntlMessages id="common.type" />}
                        id="interactionTypeId"
                        name="interactionTypeId"
                        disabled={isDisabled}                          
                        value = {interactionTypeId === "00000000-0000-0000-0000-000000000000" ? selectedEvent?.interactionTypeId : interactionTypeId}                        
                        select                        
                        onChange={(e) => {
                          setFieldValue('interactionTypeId',e.target.value);     
                                              
                          handleInteractionTypeIdChange(e);
                        }}
                        error={!!errors['interactionTypeId'] } // Verifique se há erro
                        helperText={!!errors['interactionTypeId'] && <IntlMessages id={"requiredfield"} />}
                      >                                        
                        <MenuItem value={selectedEvent?.interactionTypeId ? selectedEvent?.interactionTypeId: ""} disabled>Selecione um valor</MenuItem>
                          {interactionType.map((event) =>
                          (
                            <MenuItem
                            style={{display: "block", padding: "5px"}}
                              value={event.InteractionTypeId}
                              key={event.InteractionTypeId}
                              sx={{
                                cursor: "pointer",
                              
                              }}
                            >
                              <Typography><IntlMessages id={event.Name} /></Typography>
                        </MenuItem>
                        )
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  }
                  </Grid>
                  <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginBottom: 5
                  }}
                >
                 <TextField
                    label={<IntlMessages id={customrerMaintenance ?"Investidor" :"Investidor (Opcional)"} />}
                    id="InvestorId"
                    name="InvestorId"                   
                    value={values.investorId === "" ? selectedEvent?.investorId : values.investorId}                  
                    disabled={isDisabled}  
                    select
                    onChange={(e) => {
                      setFieldValue('investorId',e.target.value);
                      
                      handleInvestorChange(e);
                    }}
                    error={!!errors['investorId']} // Verifique se há erro
                    helperText={!!errors['investorId'] && <IntlMessages id={"requiredfield"} />}
                  >
                    <MenuItem value={selectedEvent?.investorId ? selectedEvent?.investorId: ""} disabled>Selecione um valor</MenuItem>                 
                      {investorData?.map((investor) =>
                      (
                        <MenuItem
                        style={{display: "block", padding: "5px"}}
                          value={investor.InvestorId}
                          key={investor.InvestorId}
                          sx={{
                            cursor: "pointer",
                          
                          }}
                        >

                          <Typography>{investor.Name}</Typography>
                          {/* {messages["schedule."+event.Name.toLowerCase()]} */}
                    </MenuItem>
                    )
                    )}
                
                </TextField>
                </FormControl>
              </Grid>
              <Box mb={5}>
                <CKEditor
                  editor={ClassicEditor}
                  data={selectedEvent.desc ? selectedEvent.desc : ""}
                  disabled={isDisabled}                    
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleDescriptionChange(data);                    
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}

                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "500px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                />
              </Box>
              <Box mb={5}>
                <AppGridContainer spacing={3}>
                  
                  {/* <Grid item xs={12} sm={6} md={3}>
                <AppTextField
                  sx={{
                    width: "100%",
                    fontWeight: Fonts.LIGHT,
                    marginBottom: 3,
                  }}
                  variant="outlined"
                  label={<IntlMessages id="telefone" />}
                  name="phoneNumber"
                />
              </Grid> */}
                  {/* <Grid item xs={12} sm={6} md={3}>
                    <AppTextField
                      sx={{
                        width: "100%",
                        fontWeight: Fonts.LIGHT,
                        marginBottom: 3,
                      }}
                      variant="outlined"
                      label={<IntlMessages id="Place" />}
                      name="Place"
                    />
                  </Grid> */}
                  <Grid container item xs={12} spacing={2}>

                    <Grid item xs={4} >
                      <DesktopDatePicker
                        label="Data Fim"                                             
                        inputFormat="dd/MM/yyyy"                        
                        value={values?.dtEnd < values.dtStart? values.dtStart: values?.dtEnd}
                        minDate={values.dtStart }
                        onChange={handleDtEndChange}
                        disabled={isDisabled}  
                        renderInput={(params) => <TextField {...params}
                          name="DtEnd"
                          style={{
                            width: "100%",
                            fontWeight: Fonts.LIGHT,
                            marginBottom: 3,
                        }} />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TimePicker 
                        label="Hora Fim"
                        value={hourEnd}
                        ampm={false}
                        disabled={isDisabled}
                        onChange={(e) => {
                         
                              setFieldValue('hourEnd',dayjs(e).format("HH:mm:ss"));      
                              setFieldValue('hourStart',hourStart);      
                              handleHourEndChange(e);
                            }
                        }
                        renderInput={(params) => <TextField {...params}
                        name="hourEnd"                        
                          style={{
                            width: "100%",
                            fontWeight: Fonts.LIGHT,
                            marginBottom: 3,
                          }} />}
                      />
                          {horaFimError && (
                                    <Grid item xs={15} sm={15} md={15}>
                                        <Typography variant="caption" color="error">
                                            <IntlMessages id={"Data/Hora Invalida! Menor Data/Hora Inicial"}/>
                                        </Typography>
                                    </Grid>
                          )}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <StyledDivider />

                    {/* <Grid xs={2}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100%"
                      >
                        <IconButton
                          type="button"
                          onClick={handleAddField}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Grid> */}
                    {/* <Button
                        sx={{
                          position: "relative",
                          minWidth: 100,
                        }}
                        onClick={handleAddField}

                        variant="contained">
                        Adicionar Notificação
                      </Button> */}


                    <FieldArray name="notifications">
                      {({ push, remove }) => (
                        <Box>
                          <Grid container>
                            <Grid xs={10}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="100%"
                              >
                                <Typography style={{ padding: "2px" }}>Adicionar Notificação</Typography>
                              </Box>
                            </Grid>
                            <Grid xs={2}>
                              <IconButton
                              
                                onClick={() => push(new Date())}
                                
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          </Grid>

                          {values.notifications.map((notification: any, index: number) => (
                            <Box
                              key={index}
                              sx={{
                                paddingBottom: 5,
                                display: "flex",

                              }}>
                              <Field name={`notifications[${index}]`}>
                                {({ field, form }: { field: any, form: any }) => (
                                  <DateTimePicker
                                    ampm={false}
                                    label="Date and Time"
                                    value={field.value}
                                    onChange={(value: Date | null) => form.setFieldValue(field.name, value || undefined)}
                                    renderInput={(props: any) => <TextField variant="outlined" {...props} />}
                                    // inputFormat="dd/MM/yyyy HH:mm"
                                    inputFormat="dd/MM/yyyy HH:mm"
                                  />
                                )}
                              </Field>
                              <IconButton
                                style={{ marginLeft: 10 }}
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}

                        </Box>
                      )}
                    </FieldArray>
                  </Grid>

                </AppGridContainer>
              </Box>
              <StyledDivider />
            </LocalizationProvider>
          </div>
          <div style={{ textAlign: "right" }}>   
          {isUpdate ?
          <>
            <Button
              name="save"
              id="save"
              sx={{
                position: "relative",
                minWidth: 100,
              }}
              color="primary"
              //variant="outlined"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
            >
              <IntlMessages id="common.save" />
            </Button>
            </>
          :
          <>
          
            <Button
                  name="edit"
                  id="edit"
                  sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                  }}
                  color="primary"
                  variant="contained"
                  type="button"                  
                  onClick={() => handleEditEvento()}                  
              >
                  <IntlMessages id="common.edit" />
            </Button>            
            <Button
                name="delete"
                id="delete"
                sx={{
                    position: "relative",
                    minWidth: 100,
                    ml: 2.5,
                }}
                color="warning"
                variant="contained"                
                onClick={() => handleRemoveEvento()}
            >
                <IntlMessages id="common.delete" />
            </Button>  
          </>
          }         
            <Button
                  name="cancel"
                  id="cancel"
                  sx={{
                      position: "relative",
                      minWidth: 100,
                      ml: 2.5,
                  }}
                  color="primary"
                  variant="contained"
                  type="reset"
                  onClick={() => fuctionSelected(false)}                  
              >
                  <IntlMessages id="common.cancel" />
            </Button>           
          </div>
          <AppInfoView />
        </Form>
      )
      }
    </Formik >

  );
};
export default AddTaskForm;

