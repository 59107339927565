import React from "react";
import { Fonts } from "../../../../shared/constants/AppEnums";
import { BalanceCoins, Contribuition } from "../../mock";
import { AppTableContainer } from "@crema";
import { Table, TableHead, TableBody, TableCell, TableRow, Box, TableContainer } from "@mui/material";
import { ContributionsAndWithdrawalsPanelData } from "../NotificationsPanel";
import moment from "moment";
import IntlMessages from "@crema/utility/IntlMessages";
interface ContributionPanelDataProps {
  contributionInfo: ContributionsAndWithdrawalsPanelData[],
}

const ContributionInfo: React.FC<ContributionPanelDataProps> = ({ contributionInfo }) => {
  contributionInfo = contributionInfo.sort((a,b) => (a.DtInsert< b.DtInsert) ? 1 : -1 )
  return (
    <TableContainer sx={{ 
      "&::-webkit-scrollbar": {
         width: 8,
         height: 8
       },                
       "&::-webkit-scrollbar-thumb": {
         backgroundColor: "primary.main",          
         borderRadius: 2
       },       
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#757575",         
        borderRadius: 2
      },
     height: 340
     }}>
      <Table 
        stickyHeader 
        aria-label="sticky table" 
        className="table" 
        //sx={{ mt: 5, mb: 5 }}
      >
        <TableHead>
          <TableRow
            key="ContributionsAndWithdrawalsPanel"
            sx={{
              position: "relative",
              "& .tableCell": {
                fontSize: 13,
                padding: 2,
                paddingTop: 0,
                fontWeight: Fonts.BOLD,
                whiteSpace: "nowrap",
                "&:first-of-type": {
                  paddingLeft: 5,
                },
                "&:last-of-type": {
                  paddingRight: 5,
                },
              },
            }}
          >
            <TableCell align="left" className="tableCell" sx={{backgroundColor: "#ffffff"}}>
              <IntlMessages id="year.month" />
            </TableCell>
            <TableCell align="left" className="tableCell" sx={{backgroundColor: "#ffffff"}}>
            <IntlMessages id="contribution" />
            </TableCell>
            <TableCell align="left" className="tableCell" sx={{backgroundColor: "#ffffff"}}>
            <IntlMessages id="withdrawal" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>          
          {contributionInfo.map((data,index) => {
            let date = data.DtInsert;
            return (
              <TableRow
                key={index}
                className="item-hover"
                sx={{
                  "& .tableCell": {
                    fontSize: 13,
                    padding: "12px 8px",
                    "&:first-of-type": {
                      paddingLeft: 5,
                    },
                    "&:last-of-type": {
                      paddingRight: 5,
                    },
                  },
                }}
              >
                <TableCell className="tableCell">
                  <Box display="flex" alignItems="center">
                    <Box fontWeight={Fonts.MEDIUM}>{date? moment(String(date)).format("MM/YYYY"): "-"}</Box>
                  </Box>
                </TableCell>
                <TableCell className="tableCell">
                  <Box> {data.Contribution?Intl.NumberFormat('pt-BR').format(Number(data.Contribution)) : "-"}</Box>
                </TableCell>
                <TableCell className="tableCell">
                  <Box> {data.Withdrawal?Intl.NumberFormat('pt-BR').format(Number(data.Withdrawal)) : "-"}</Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContributionInfo;
