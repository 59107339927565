export const configs = {
    Params: {
      pageName: "clients",
      urlFront: "admin/investors",
      urlApi: "investors",
      buttonFilter: true,
      isSearch: false,
      isSearchInit: true,
      action : "GetallByInvestors",
      ShowAddButton: false,
      enabledOnHeader: true,
      filterColumns:[
        // { id: "account",Field: "AccountNumber", type: "String" },
        { id: "name", Field: "Name", type: "String" },
        { id: "investorCategory", Field: "InvestorCategory", type: "String" },
        { id: "totalpl", Field: "TotalPL", type: "String" },
        { id: "incomeAverage", Field: "IncomeAverage", type: "String" },
        { id: "status", Field: "Status", type: "String" },
     
      ]
    },
    Fields: {
      InvestorId: {
        type: "hidden",
        label: "",
        value: "key",
        required: false,
  
      },
      Name: {
        type: "text",
        label: "name",
        value: "",
        required: true
      },
      Surname: {
        type: "text",
        label: "surname",
        value: "",
        required: true
      },
      IsActive: {
        type: "select",
        label: "active",
        value: "",
        required: true,
        options: [
          {
            label: "active",
            value: "true"
          },
          {
            label: "inactive",
            value: "false"
          }
        ]
      }
    },
    List: {
      filter: {
        Name: {
          type: "text",
          label: "name",
          value: "",
          required: true,
          xs: "12",
          sm: "6",
        },
        IsActive: {
          type: "select",
          label: "active",
          value: "true",
          required: true,
          options: [
            {
              label: "active",
              value: "true"
            },
            {
              label: "inactive",
              value: "false"
            }
          ],
          xs: "12",
          sm: "6",
        }
      },
      gridColumns: [
        { id: "actions", Field: "Actions", minWidth: 100, show: true, isActions: true, align: "center" , isSearch:false},
        { id: "investorId", Field: "InvestorId", type: "Guid", minWidth: 170, show: false, isKey: true, isSearch: false,isNotTranslate:true },
        //{ id: "account", Field: "AccountNumber", type: "String", minWidth: 170, show: true, isSearch: true },
        { id: "name", Field: "Name", type: "String", minWidth: 300, show: true, isSearch: true },
        { id: "investorCategoryName", Field: "InvestorCategoryName", type: "String", minWidth: 170, show: true, isSearch: true },
        { id: "investorCategoryDate", Field: "InvestorCategoryDate", type: "date",format: "date", minWidth: 150, show: true, isSearch: true , align: "start"},
        { id: "totalpl", Field: "TotalPL", type: "String", minWidth: 150, format:"money", show: true, isSearch: true },
        { id: "IncomeAverage", Field: "ContribuitionValue", type: "String", minWidth: 150, format:"money", show: true, isSearch: true },
        { id: "lastContact", Field: "LastContact", type: "string",format: "date", minWidth: 150, show: true, isSearch: true , align: "center", isDashboardField: true},
        { id: "lastContactType", Field: "LastContactType", type: "String", minWidth: 170, show: true, isSearch: true },
        { id: "nextContact", Field: "NextContact", type: "string",format: "date", minWidth: 150, show: true, isSearch: true , align: "center", isDashboardField: true},
        { id: "nextContactType", Field: "NextContactType", type: "String", minWidth: 170, show: true, isSearch: true },
        { id: "status", Field: "Status", type: "String", minWidth: 170, show: true, isSearch: true },
        { id: "obs", Field: "Obs", type: "String", minWidth: 300, show: true, isSearch: true },

        //{ id: "suitability", Field: "SuitabilityExpirationDate", type: "String",format: "date", minWidth: 170, show: true, isSearch: true },
        //{ id: "annualincome", Field: "AnnualIncome", type: "String", minWidth: 170, format:"money", show: true, isSearch: true },
        //{ id: "declaredpl", Field: "DeclaredPL", type: "String", minWidth: 170, format:"money", show: true, isSearch: true },
      
        //{ id: "openingdate", Field: "OpeningDate", type: "String", format: "date", minWidth: 170, show: true, isSearch: true },
        // { id: "lastmovementdate", Field: "LastMovementDate", minWidth: 170, show: true , isSearch:true},
        // { id: "qtdcontribuition", Field: "QtdContribuition", minWidth: 170, show: true , isSearch:true},      
        // { id: "active", Field: "Status", minWidth: 170, show: true , isSearch:false},       
      
      ],    
      actions: [
        //{ id: "edit", Field: "edit", icon: "Edit" },
        { id: "info", Field: "info", icon: "Info" },
      ]
    }
  };