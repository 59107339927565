import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import { useDispatch } from "react-redux";
import moment from "moment";
import IntlMessages from "@crema/utility/IntlMessages";

import { useIntl } from "react-intl";
import AddTaskForm from "./AddTaskForm";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppAnimate, AppCard, AppGridContainer } from "@crema";

interface EventsData {
  eventId: string;
  scheduleId: string;
  userId: string;
  eventTypeId: string;
  title: string;
  description: string;
  phoneNumber: string;
  email: string;
  place: string;
  dtStart: string;
  dtEnd: string;
  hourStart: string;
  hourEnd: string;
  whats: string;
  site: string;
  imagem: string;
  street: string;
}

interface EventProps {
  selectedEvent: any;
  fuctionSelected: any;
  functionCloseChield: any;
}
const AddNewTask: React.FC<EventProps> = ({ selectedEvent,fuctionSelected,functionCloseChield }) => {
  const { messages } = useIntl();
  const [taskLabels, setTaskLabels] = useState<[]>([]);

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <AppCard contentStyle={{ overflowY: "scroll" }}>
        <AddTaskForm
          selectedEvent={selectedEvent}
          fuctionSelected={fuctionSelected}
          functionCloseChield={functionCloseChield}
        />
      </AppCard>
    </AppAnimate>
  );
};

export default AddNewTask;
