import React from 'react';
import { useEffect, useState } from "react";
import { Fonts } from '../../../shared/constants/AppEnums';
import IntlMessages from "@crema/utility/IntlMessages";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, Grid, MenuItem, Popover, Select, TextField, Typography } from "@mui/material";
import ButtonBack from '../buttonBack';
import MultipleSelectEva from '../multipleSelectEva';
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface IFilter {
  andOrFilter: string,
  nameFilter: string,
  operadorFilter: string,
  valueFilter: string,
}

const Header = ({ dataParam, showButtonAdd, showButtons, showButtonFilter,filterFunction, showButtonSelect, selectFunction,urlId,showButtonUpload,showButtonBack }:{
  dataParam: any,
  showButtonAdd: boolean,
  showButtons: boolean,
  showButtonFilter:boolean,
  filterFunction?: any,
  showButtonSelect?:boolean,  
  selectFunction?:any,
  urlId?:string,
  showButtonUpload?:boolean,
  showButtonBack:boolean,
}) => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {   
    setAnchorEl(event.currentTarget);
    if(!open){
      setOpen(!open); 
      setId('simple-popover');   
    }
  };

  
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
    setId("");
    setColumnFilter("");
  };
  
 


  const [operadorFilter, setOperadorFilter] = useState("");

  const handleChangeOperator = (event: { target: { value: string } }) => {
    setOperadorFilter(event.target.value);
  }
  const [columnFilter, setColumnFilter] = useState("");
  const handleChangeColumn = (event: { target: { value: string } }) => {
    setColumnFilter(event.target.value);
  }

  const [valueFilter, setValueFilter] = useState("");
  const handleChangeValue = (event: { target: { value: string } }) => {
    setValueFilter(event.target.value);
   
  }

  const handleClickButton =() =>{
    
    if(valueFilter!== ""){    
      
    filterFunction(columnFilter,operadorFilter,valueFilter,handleCloseChield);
    }
    else{
      handleCloseChield();
    }
  };


  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    initForm();
  }, [anchorEl])

 
 

  async function initForm() {
    setOpen(Boolean(anchorEl));
    var idNew = open ? 'simple-popover' : "";
    setId(idNew);
  }

const handleCloseChield = () => {
    setAnchorEl(null);
    setOpen(!open);
    setId("");
    setColumnFilter("");

    if(showButtonSelect){    
      selectFunction();
    }
  };

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
        pb: 5,
        mb: 5,
        borderBottom: '1px solid #E5E4EA',
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Box
          component='h3'
          sx={{
            color: 'text.primary',
            fontWeight: Fonts.BOLD,
            fontSize: 16,
            mb: 1,
          }}
        >
          <IntlMessages id={dataParam.pageName + ".title"} />
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              ml: 2,
              mr: 4,
              color: 'text.secondary',
            }}
          >
            <IntlMessages id={dataParam.pageName + ".subtitle"} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: { xs: 4, sm: 0 },
        }}
      >
        {showButtonFilter ? 
        
          <Button variant="outlined"  sx={{ mr: 2 }} onClick={(event) =>  handleClick(event) } >
            <IntlMessages id={"common.filter"} />
            <FilterAltOutlinedIcon sx={{ fontSize: 28 }} />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: 1,
                  overflow: "hidden"
                }
              }}
            ><Box
                sx={{
                  position: "relative",
                  //mt: "-10px",
                  "&::before": {
                    backgroundColor: "white",
                    content: '""',
                    display: "block",
                    position: "absolute",
                    width: 12,
                    height: 12,
                    top: 104,
                    transform: "rotate(45deg)",
                    left: "calc(50% - 6px)",
                    borderBottom: 1,
                    borderRight: 1,
                    borderColor: "#cccccc"
                  }
                }}
              />
              <Typography sx={{ height: 110, width: 600, mb: "20px", backgroundColor: "white", border: 1, borderColor: "#cccccc" }}>
                <Grid item xs={12}>
                  <Typography sx={{ p: 1, backgroundColor: "#0D4B7C", color: "white" }}>
                    Adicionar filtro
                  </Typography>
                </Grid>
                <Grid container spacing={1} sx={{ p: 2 }}>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      Coluna
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{  minWidth: 190 }} size="small">
                      <Select
                          value={columnFilter}
                          onChange={handleChangeColumn}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {
                            dataParam.filterColumns.map((item, index) => {   
                              
                              return (                                
                                <MenuItem key={item.Field} value={item.Field}><IntlMessages id={String("common." + item.Field.toLowerCase())} /></MenuItem>
                                )
                              })}
                          
                          </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item xs={3}>
                      Operador
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ minWidth: 70 }} size="small">
                        <Select
                          value={operadorFilter}
                          onChange={handleChangeOperator}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value=">">{">"}</MenuItem>
                          <MenuItem value="<">{"<"}</MenuItem>
                          <MenuItem value="=">{"="}</MenuItem>
                          <MenuItem value=">=">{">="}</MenuItem>
                          <MenuItem value="<=">{"<="}</MenuItem>
                          <MenuItem value="!=">{"!="}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      Valor
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ minWidth: 100 }} size="small">
                        <TextField
                          variant="outlined"
                          size="small"
                          onChange={handleChangeValue}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        right: 0,
                        bottom: 0,

                      }}
                    >
                      <Button
                        sx={{
                          position: "relative",
                          minWidth: 60,
                          top: 20
                        }}
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleClickButton}
                      >
                        <IntlMessages id={"common.filter"} />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Typography>
            </Popover>
          </Button>
          :""
        }

        {showButtonAdd === true ? 
          <Button variant="outlined" sx={{ mr: 2 }} onClick={() => navigate(`/${dataParam.urlFront}`)} >
            <IntlMessages id={"addNew"} />
            <AddIcon sx={{ fontSize: 28 }} />
          </Button>
          :""
        }
       
        {showButtonSelect=== true ? 
        
          <Button variant="outlined"  sx={{ mr: 2 }} onClick={(event) =>  handleClick(event) } >
            <IntlMessages id={dataParam.selectConfig.titleButton} />            
            <AddIcon sx={{ fontSize: 28 }} />            
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="anchorEl"
              PaperProps={{
                style: {
                  maxWidth: 500,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: 1,
                  overflow: "hidden"
                }
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  //mt: "-10px",
                  "&::before": {
                    backgroundColor: "white",
                    content: '""',
                    display: "block",
                    position: "absolute",
                    width: 12,
                    height: 12,
                    top: 104,
                    transform: "rotate(45deg)",
                    left: "calc(50% - 6px)",
                    borderBottom: 1,
                    borderRight: 1,
                    borderColor: "#cccccc"
                  }
                }}
              />
              <Typography sx={{ minHeight: 120, minWidth: 100, mb: "20px", backgroundColor: "white", border: 1, borderColor: "#cccccc" }}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ p: 1, backgroundColor: "#0D4B7C", color: "white" }}>
                      <IntlMessages id={dataParam.selectConfig.titleWindow} />  
                    </Typography>
                  </Grid>                  
                  <Grid item xs={12}>
                    <MultipleSelectEva dataParam={dataParam} functionCloseChield={handleCloseChield} urlId={urlId}/>
                  </Grid>
                  
                </Grid>
              </Typography>
            </Popover>
          </Button>
          :""
        }

        {showButtons === true ?
          <>
            <LinkedinShareButton url='teste' style={{ marginRight: 10 }}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
            <FacebookShareButton url='teste' style={{ marginRight: 10 }}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url='teste'>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </>
          :""
        }

      {showButtonUpload=== true ? 
        
        <Button variant="outlined" sx={{ mr: 2 }} onClick={() => navigate(`/${dataParam.urlFront}`)} >
        <IntlMessages id={"sidebar.uploadfiles"} />
        <FileUploadIcon sx={{ fontSize: 28 }} />
      </Button>
      :""
      }

      {showButtonBack &&
        <ButtonBack/>
      }
        

      </Box>
    </Box>
  );
};

export default Header;

Header.propTypes = {
  title: PropTypes.string,
  subtitles: PropTypes.string,
};
