import React, { createContext, useState } from 'react';

export interface FilterContextData {
  filter: string;
  setFilter: (value: string) => void;
}

export const FilterContext = createContext<FilterContextData>({
  filter: '',
  setFilter: () => {},
});

export const FilterProvider: React.FC = ({ children }) => {
  const [filter, setFilterState] = useState('');

  const setFilter = (value: string) => {
    // Perform any necessary validation or modifications to the value
    setFilterState(value);
  };

  const contextValue: FilterContextData = {
    filter,
    setFilter,
  };
 
  //console.log(filter, "filter")
  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

export default FilterProvider;