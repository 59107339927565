import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import jwtAxios, { setAuthToken } from "./index";
import { AuthUser, RouterConfigData } from "../../../../types/models/AuthUser";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions";
import { defaultTheme } from "@crema/utility/AppContextProvider/defaultConfig";
import { useThemeActionsContext, useThemeContext } from "@crema/utility/AppContextProvider/ThemeContextProvider";
import * as CryptoJS from 'crypto-js';
import { FilterContext } from "@crema/services/filter/FilterProvider";
interface dataTableMenu {
  MenuId: string;
  ParentMenuId: string;
  Name: string;
  Description: string;
  Target: string;
  Icone: string;
  Link: string;
  Alt: string;
  HasChildren: boolean;
  Space: string;
  Children: dataTableMenu[];
}

interface JWTAuthContextProps {
  user: UserSessionData | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  scheduleId: string;
  menu: RouterConfigData[];
}
interface TenantContextProps {
  tenantParams: any | null | undefined;
}
interface UserSessionData {
  CellPhone: string;
  Cpf: string;
  DtBirth: string;
  Email: string;
  FindItens: string;
  Image: string;
  IsActive: string;
  IsWhatsapp: string;
  MaritalStatusId: string;
  Name: string;
  NameFather: string;
  NameMother: string;
  Nationality: string;
  NbPis: string;
  Phone: string;
  ProfileId: string;
  Rg: string;
  SocialName: string;
  Surname: string;
  TenantId: string;
  UserId: string;
}
interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

interface TenantActionsProps {
  setTenantParams: (data: any) => void;
}

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}
const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  scheduleId: "",
  isAuthenticated: false,
  isLoading: true,
  menu: [],
});

const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => { },
  signInUser: () => { },
  logout: () => { },
});

export const useJWTAuth = () => useContext(JWTAuthContext);
export const useFilter = () => useContext(FilterContext);
export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);




const TenantContext = createContext<TenantContextProps>({
  tenantParams: null
});
const TenantActionsContext = createContext<TenantActionsProps>({
  setTenantParams: () => { }
});

export const useTenant = () => useContext(TenantContext);
export const useTenantActions = () => useContext(TenantActionsContext);

export const TenantProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children = [{}],
}) => {
  const [tenantData, setJwtTenantData] = useState<TenantContextProps>({
    tenantParams: null
  });

  const { theme } = useThemeContext();

  const { updateTheme } = useThemeActionsContext();

  useEffect(() => {
    const getTenantParam = async () => {
      //const exampleURL = "https://boavista.evamind.com.br";
      //const exampleURL = "https://maisaai.evamind.com.br/signin";
      //  https://demo.evamind.com.br/signin
      let tenantsParameters: any = {};
      let url = window.location.href.split("//", 2)[1].split(".", 1)[0];
      //let url = exampleURL.split("//",2)[1].split(".", 1)[0];

      if (url.includes("localhost")) {
        url = "localhost";
      }
      //const url = exampleURL.split(".", 1)[0];
      if (url !== undefined) {
        // Dynamically imported module (runtime)
        tenantsParameters = await import(`shared/tenants/${url}`);
        defaultTheme.theme.palette.primary.main = tenantsParameters.default.mainColor;
        theme.palette.primary.main = tenantsParameters.default.mainColor;

        updateTheme({ ...theme });

        setJwtTenantData({
          tenantParams: tenantsParameters.default,
        })

      }

      return;
    }

    getTenantParam();
  }, []);

  const setTenantParams = async (tenantsParams) => {

  }

  return (
    <TenantContext.Provider
      value={{
        ...tenantData,
      }}
    >
      <TenantActionsContext.Provider value={{ setTenantParams }}>
        {children}
      </TenantActionsContext.Provider>
    </TenantContext.Provider>
  );
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children = [{}],
}) => {
  const [jWTAutData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    scheduleId: "",
    isAuthenticated: false,
    isLoading: true,
    menu: [],
  });



  // Declare this key and iv values in declaration
  const key = CryptoJS.enc.Utf8.parse('4512631236589784');
  const iv = CryptoJS.enc.Utf8.parse('4512631236589784');

  const dispatch = useDispatch();


  let menusConfig: RouterConfigData[] = [];
  useEffect(() => {

    const getAuthUser = async () => {
      
      const token = localStorage.getItem("token");
      if (!token) {
        setJWTAuthData({
          user: null,
          scheduleId: "",
          isLoading: false,
          isAuthenticated: false,
          menu: [],
        });
        return;
      }

      setAuthToken(token);

      const res = await jwtAxios.get("/login/GetDataLogin");

      localStorage.setItem("tenantId", res.data.Users.TenantId);
      //localStorage.setItem("profileId", res.data.Users.ProfileId);
      //carregando os Menus
      menusConfig = getChildren(res.data.Menus);
      localStorage.removeItem("menus");
      localStorage.setItem("menus", JSON.stringify(menusConfig));
      setJWTAuthData({
        user: res.data.Users,
        scheduleId: res.data.ScheduleId,
        isAuthenticated: res.data.Logado,
        isLoading: false,
        menu: menusConfig,

      });
    };
    getAuthUser();

  }, []);

  function getChildren(item: dataTableMenu[]) {
    let lstItemMenu: RouterConfigData[] = [];

    item.map((ch: dataTableMenu) => {
      let itemMenu: RouterConfigData = {} as RouterConfigData;
      itemMenu.id = ch.MenuId;
      itemMenu.title = ch.Name;
      itemMenu.messageId = `sidebar.${ch.Alt?.toLowerCase()}`;
      itemMenu.type = ch.Children.length > 0 ? "collapse" : "item";
      itemMenu.icon = ch.Icone;
      itemMenu.url = ch.Link;

      if (ch.Children.length > 0) {
        itemMenu.children = getChildren(ch.Children);
      }

      lstItemMenu.push(itemMenu);
    });
    return lstItemMenu;
  };

  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {

    dispatch(fetchStart());
    try {
      
      var encPassword: string = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      await jwtAxios.post("/login", { Login: email, Password: encPassword }).then((res) => {        
        localStorage.setItem("token", res.data.Token);
        setAuthToken(res.data.Token);
      });
      const res = await jwtAxios.get("/login/GetDataLogin");
      
      localStorage.setItem("tenantId", res.data.Users.TenantId);
      //localStorage.setItem("profileId", res.data.Users.ProfileId);
      //carregando os Menus
      menusConfig = getChildren(res.data.Menus);
      localStorage.removeItem("menus");
      localStorage.setItem("menus", JSON.stringify(menusConfig));

      dispatch({ type: 'ATUALIZAR_FILTRO', filtro: res.data.Users.UserId, vision: "see_one", enabledOnHeader:true, filtermodal:''}); 
      
      setJWTAuthData({
        user: res.data.Users,
        scheduleId: res.data.ScheduleId,
        isAuthenticated: res.data.Logado,
        isLoading: false,
        menu: menusConfig,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jWTAutData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Email ou senha inválidos."));
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.Token);
      setAuthToken(data.Token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        scheduleId: res.data.ScheduleId,
        isAuthenticated: true,
        isLoading: false,
        menu: menusConfig
      });


      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jWTAutData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("menus");
    //localStorage.removeItem("profileId");
    setAuthToken();
    setJWTAuthData({
      user: null,
      scheduleId: "",
      isLoading: false,
      isAuthenticated: false,
      menu: [],
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jWTAutData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
