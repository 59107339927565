import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AppLngSwitcher from "@crema/core/AppLngSwitcher";
import Box from "@mui/material/Box";
import AppSearchBar from "@crema/core/AppSearchBar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import { toggleNavCollapsed } from "../../../../../redux/actions";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import AppMessages from "../../../AppMessages";
import AppNotifications from "../../../AppNotifications";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha } from "@mui/material/styles";
import AppLogo from "../../components/AppLogo";
import UserInfo from "../../components/UserInfo";
import { useNavigate } from "react-router-dom";
import { EventAvailable } from "@mui/icons-material";
import { Badge, Button, FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { FilterContextData, FilterContext } from "@crema/services/filter/FilterProvider";
import { AppState } from "redux/store";
import IntlMessages from "@crema/utility/IntlMessages";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScriptReader from "pages/admin/scripts/scriptReader";
import ScriptReaderList, { IScripts } from "pages/admin/scripts/scriptReaderList";


interface Users {
  Name: string;
  Surname: string;
  UserId: string;
  AccessorCode: string;
}

interface Wallets {
  WalletId: string;
  Name: string;  
}

interface MenuVision{
  id: string,
  value:string,
}

export const filterByDate = (item: any) => {
  const dataItem = new Date(item.AlertTime);
  dataItem.setSeconds(0);

  // Cria um objeto de data para a data atual
  const hoje = new Date();

  // Compara as datas

  //regra do menor ou igual 
  return dataItem.getTime() < hoje.getTime() || dataItem.getTime() === hoje.getTime() && item.IsRead === false;
}

const AppHeader = () => {
  const { filtro, vision, enabledOnHeader } = useSelector<AppState, AppState["filter"]>(({ filter }) => filter);
  const filter = filtro;
  const { user } = useAuthUser();




  const [notificationscount, setNotificationsCount] = useState<number>(0);
  // const { filter, setFilter } = useContext<FilterContextData>(FilterContext);
  const [canViewAllData, setCanViewAllData] = useState(false);
  const [users, setUsers] = useState<Users[]>([]);
  const [wallets, setWallets] = useState<Wallets[]>([]);
  

  useEffect(() => {    
    getNotificationsLength();
    jwtAxios.post("/users/getbyfilter", { ParamSearch: { IsActive: true }, CountRecordsPerPage: 100  }).then((res) => {      
      //setUsers(res.data.List.filter((usuario) => usuario.UserId !== user?.userId && usuario.AccessorCode !== null))
      var users_Aux = res.data.List.sort((a,b) => (a.Name< b.Name) ? -1 : 1 );      
      setUsers(users_Aux.filter((usuario) => usuario.AccessorCode !== null && usuario.AccessorCode !== ""))
    })
    // const profileCanView = () => {
    jwtAxios.get("/profiles/getbyid/" + user?.profileId).then((res) => {
      setCanViewAllData(res.data.CanViewAllData);
    })

    jwtAxios.get("/wallets/getallbyuserid/" + user?.userId).then((res) => {
      setWallets(res.data);
    })
  }, [filter, vision]);

  

  const listUpdated = () => {
    getNotificationsLength();
  }

  const getNotificationsLength = () => {
    if(vision !== "see_all"){
      jwtAxios.post("/notifications/getbyfilter", { ParamSearch: { UserId: filter !== ""? filter: user?.userId, isRead: false, isActive: true, isDeleted: false }, CountRecordsPerPage: 100 }).then((res) => {
        var formattedNotifications = res.data.List.filter(filterByDate);
        setNotificationsCount(formattedNotifications.length)
      });
    }
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filterType, setFilterType] = React.useState("")
  const [filterUserId, setFilterUserId] = React.useState("")
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  
  const handleClose = () => {
    setAnchorEl(null);
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFilterClick = () => {

    if(filterType === "see_all") {
      dispatch({ type: 'ATUALIZAR_FILTRO', filtro: user?.userId, vision: filterType, enabledOnHeader:true, filterDataTable:'',filterCustom:''});
    }
    else {
      dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filterUserId, vision: filterType, enabledOnHeader: true, filterDataTable: '',filterCustom:'' });
    }
  }

  const handleFilterTypeChange = (event: SelectChangeEvent) => {
    setFilterType(event.target.value)
  }

  


  return (
    <AppBar
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.paper",
        transition: "width 0.5s ease",
        width: "100%",
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 56, sm: 70 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5, md: 7.5, xl: 12.5 },
        }}
      >
        <Hidden lgUp>
          <IconButton
            sx={{
              color: "text.secondary",
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            "& .logo-text": {
              display: { xs: "none", sm: "block" },
            },
          }}
        >
          <AppLogo />
        </Box>
        <Box
          sx={{
            flexGrow: 0.3,
          }}
        />

        <div style={{ display: "flex", width: "50%", justifyContent: "space-between", alignItems: "center", margin: "0 40" }}>
          {(enabledOnHeader && canViewAllData) 
          ?
            <>
              <FormControl style={{ margin: "0 10px", width: filterType === "see_one" ? "80%" : '40%' }}>
                <InputLabel id="demo-simple-select-label"><IntlMessages id={"vision"} /></InputLabel>
                <Select
                  onChange={handleFilterTypeChange}
                  value={filterType}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"                  
                >
                  <MenuItem value="see_all"><IntlMessages id={"office"} /></MenuItem>                  
                  <MenuItem value="see_one"><IntlMessages id={"advisor"} /></MenuItem>  
                  {wallets.length>0 &&
                    <MenuItem value="see_wallets"><IntlMessages id={"wallets"} /></MenuItem>
                  }
                  
                </Select>
              </FormControl >
              {filterType === "see_one" 
              ?
                <FormControl style={{ margin: "0 10px", width: "80%" }}>
                  <InputLabel id="demo-simple-select-label"><IntlMessages id={"advisor"} /></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filterUserId}
                    onChange={(e) => setFilterUserId(e.target.value)}
                  >
                    {users.map((user,index) => (
                      <MenuItem key={index} value={user.UserId}>{user.Name + " " + (user.Surname!== null ? user.Surname : " " )}</MenuItem>
                    ))}
                  </Select>
                </FormControl> 
              : 
              filterType === "see_wallets" 
              ?
                <FormControl style={{ margin: "0 10px", width: "80%" }}>
                  <InputLabel id="demo-simple-select-label"><IntlMessages id={"wallets"} /></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filterUserId}
                    onChange={(e) => setFilterUserId(e.target.value)}
                  >
                    {wallets.map((wallet,index) => (
                       <MenuItem key={index} value={wallet.WalletId}>{wallet.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl> 
                :
                null}
              <Button
                sx={{
                  position: "relative",
                  width: 64,
                  height: 48,
                }}
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleFilterClick()}
              // disabled={isSubmitting}>
              // <IntlMessages id={"common.send"} />
              >
                Filtrar
              </Button>
            </>
          : 
          (enabledOnHeader && !canViewAllData && wallets.length>0) 
          ?
            <>
              <FormControl style={{ margin: "0 10px", width: filterType === "see_one" ? "80%" : '40%' }}>
                <InputLabel id="demo-simple-select-label"><IntlMessages id={"vision"} /></InputLabel>
                <Select
                  onChange={handleFilterTypeChange}
                  value={filterType}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"                  
                >
                  <MenuItem value="see_wallets"><IntlMessages id={"wallets"} /></MenuItem>
                </Select>
              </FormControl >
              {filterType === "see_wallets" 
              ?
                <FormControl style={{ margin: "0 10px", width: "80%" }}>
                  <InputLabel id="demo-simple-select-label"><IntlMessages id={"wallets"} /></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filterUserId}
                    onChange={(e) => setFilterUserId(e.target.value)}
                  >
                    {wallets.map((wallet,index) => (
                      <MenuItem key={index} value={wallet.WalletId}>{wallet.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl> 
              : 
                null}
              <Button
                sx={{
                  position: "relative",
                  width: 64,
                  height: 48,
                }}
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleFilterClick()}
              // disabled={isSubmitting}>
              // <IntlMessages id={"common.send"} />
              >
                Filtrar
              </Button>
            </>
          : 
          null}
        </div>


        <Box sx={{
          flexGrow: 0.3,
        }} />
        <Box sx={{ ml: 4 }}>
          <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
        </Box>        

        <Box sx={{ ml: 4 }}>

          <IconButton
            className="icon-btn"
            sx={{
              borderRadius: "50%",
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: "transparent",
              "&:hover, &:focus": {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
            }}
            onClick={() => navigate("/admin/schedule")}
            size="large"
          >
            <EventAvailable />
          </IconButton>
        </Box>

        <Box
          sx={{
            ml: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          {(vision!== "see_all" && (filter === user?.userId || !canViewAllData)) &&
            <Hidden smDown>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: -2,
                  marginRight: -2,
                }}
              >

                <Box
                  sx={{
                    px: 1.85,
                  }}
                >
                  <Badge badgeContent={notificationscount} color="error" max={100} overlap="circular">
                    <AppNotifications onListUpdate={listUpdated} />
                  </Badge>
                </Box>
                <Box
                  sx={{
                    px: 1.85,
                  }}
                >

                </Box>
              </Box>
            </Hidden>
          }
          <Box
            sx={{
              ml: { sm: 4 },
              mr: { xs: 4, sm: 0 },
              minWidth: { md: 220 },
              "& .user-info-view": {
                p: 0,
              },
              "& .user-info": {
                display: { xs: "none", md: "block" },
              },
            }}
          >
            <UserInfo />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
