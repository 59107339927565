import React, { useEffect } from 'react';
import IntlMessages from "@crema/utility/IntlMessages";
import { Button, Grid, Box, MenuItem, TextField, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import jwtAxios from '@crema/services/auth/jwt-auth';
import * as FontAwesome from "react-icons/fa";
import * as base from "../base";
import InputMask from 'react-input-mask';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toNamespacedPath } from 'path';
import * as CryptoJS from 'crypto-js';
import { boolean } from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export interface ElementParameters {
    labels,
    elemName: string,
    value: string,
    elementSchema: elementSchema,
    errors,
    touched,
    handleChange,
    allStates?,
    index?,
    isDisabled?:boolean,

}


export interface elementSchema {
    label: any;
    options: any;
    type: string;
    value: string;
    placeholder: string;
    fieldId?: string;
    fieldValue?: string,
    externalURL?: string,
    xs?: string,
    sm?: string,
    mask?: string,
    selectValue? : string,    
}


 

export function DtPicker(props){
    
    const { name, label, placeholder, value, errors, touched, handleChange, isVisible, ...rest } = props;
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={value ?? " "}
                        onChange={(date) => handleChange({ name: name, value: date })}
                        renderInput={(params) => <TextField {...params} />}
                        id={name}
                        name={name}
                        fullWidth
                        inputFormat='dd/MM/yyyy'
                        placeholder={placeholder || ''}
                        label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                        {...rest}
                        error={!!errors && touched}
                        helperText={errors}
                        className={isVisible ? "visible" : "notVisible"}
                    />
                </LocalizationProvider>
        </Grid>
        </>
    )
}


export function TextFieldHidden(props) {
    const { name, value } = props;
    return (
        <input type="hidden" value={value ?? ""} name={name} id={name} />
    )
}

export function TextFieldEva(props) {
    const { name, label, placeholder, value, errors, touched, handleChange, isVisible, ...rest } = props;
    let visible = isVisible ? "visible" : "notVisible"
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} className={visible}>
                <InputMask mask={props.mask ?? ""} value={value ?? ""} onChange={handleChange}>
                    <TextField
                        variant="outlined"
                        name={name}
                        id={name}
                        fullWidth
                        placeholder={placeholder || ''}
                        label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                        {...rest}
                        error={!!errors && touched}
                        helperText={errors}
                        className={isVisible ? "visible" : "notVisible"}
                    />
                </InputMask>
            </Grid>
        </>
    )
}

export function TextFieldPassword(props) {
    const { name, label, placeholder, value, errors, touched, handleChange, isVisible, ...rest } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                {/* <TextField
                    value={value ?? " "}
                    type="password"
                    variant="outlined"
                    name={name}
                    id={name}
                    fullWidth
                    placeholder={placeholder || ''}
                    label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                    {...rest}
                    error={!!errors && touched}
                    helperText={errors}
                    onChange={handleChange}
                //style={{visibility: isVisible ? 'visible' : 'hidden'}}
                /> */}

                <FormControl variant="outlined">
                        <InputLabel htmlFor={name}><IntlMessages id={`common.${label?.toLowerCase()}`}/></InputLabel>
                        <OutlinedInput
                           id={name}
                           value={value ?? " "}
                           name={name}
                           {...rest}
                           fullWidth
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors && touched}
                            
                            onChange={handleChange}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label={<IntlMessages id={`common.${label?.toLowerCase()}`}/>}
                        />
                </FormControl>
            </Grid>
        </>
    )
}


export function TextFieldAreaEva(props) {
    const { name, label, placeholder, value, errors, touched, handleChange, ...rest } = props;
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <TextField
                    value={value ?? ""}
                    multiline
                    rows={4}
                    variant="outlined"
                    name={name}
                    id={name}
                    fullWidth
                    placeholder={placeholder || ''}
                    label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                    {...rest}
                    error={!!errors && touched}
                    helperText={errors}
                    onChange={handleChange}

                />
            </Grid>
        </>
    )
}

export function ImageDropzone(props) {
    const { name, label, value, options, placeholder, errors, touched, handleChange, ...rest } = props;
    return (
        <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
            <div style={{ display: 'grid', margin: '10px' }}>
                {value ?
                    <img style={{ borderRadius: '50%', objectFit: 'fill', width: '200px', height: '200px', margin: '5px', paddingBottom: '10px' }} src={value} alt={placeholder} />
                    : null}
                <label htmlFor="AvatarPhoto" className="avatarLabel">Logo do Assinante</label>
                <input id={name} name={name} type="file" onChange={handleChange} accept="image/x-png,image/gif,image/jpeg" />
            </div>
        </Grid>
    );
}

export function SelectFieldEva(props) {
    const { name, label, options, value, errors, touched, handleChange, ...rest } = props;

    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <FormControl sx={{ minWidth: 120 }} size="small">
                    <TextField
                        value={value ?? ""}
                        id={name}
                        name={name}
                        label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                        select
                        {...rest}
                        error={!!errors && touched}
                        helperText={errors}
                        onChange={handleChange}
                    >
                        <MenuItem value="" selected><em><IntlMessages id="common.selectone" /></em> </MenuItem>
                        {options.map((optn, index) => <MenuItem key={index} value={optn.value}> <IntlMessages id={`common.${optn.label || optn.value}`} /></MenuItem>)}
                    </TextField>
                </FormControl>
            </Grid>
        </>
    )
}

export function RadioButtons(props) {
    const { name, label, options, value, errors, touched, handleChange, fieldId, fieldValue, externalURL, ...rest } = props;
    const [externalData, setExternalData] = React.useState<string[]>([]);
    useEffect(() => {
        jwtAxios.get(externalURL).then((res: any) => {
            setExternalData(res.data);
        })
    }, [])
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <FormLabel>Type Tenant</FormLabel>
                <RadioGroup
                    row
                    value={value ?? ""}
                    id={name}
                    name={name}
                    onChange={handleChange}
                >
                    {options.map((optn, index) =>
                        <FormControlLabel key={index} value={optn.value} control={<Radio />} label={<IntlMessages id={`common.${optn.label?.toLowerCase() || optn.value?.toLowerCase()}`} />} />
                    )}


                </RadioGroup>
            </Grid>
        </>
    )
}

export function SelectExternal(props) {    
    const { name, label, options, value, errors, touched, handleChange, fieldId, fieldValue, externalURL,isDisabled, ...rest } = props;
    const [externalData, setExternalData] = React.useState<string[]>([]);
    
    useEffect(() => {
        jwtAxios.get(externalURL).then((res: any) => {            
            setExternalData(res.data.sort());
        })
    }, [])
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <FormControl 
                 sx={{ 
                    minWidth: "100%" ,
                    overflow: "hidden",             // Define o overflow para ocultar o texto excedente
                    maxWidth: "100%",               // Define a largura máxima da caixa
                    whiteSpace: "nowrap",           // Impede que o texto quebre automaticamente
                    textOverflow: "ellipsis",       // Adiciona reticências (...) ao texto que não cabe        
                }}>
                    <TextField
                        value={value ?? ""}
                        id={name}
                        name={name}
                        label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                        select
                        {...rest}
                        error={!!errors && touched}
                        helperText={errors}
                        onChange={handleChange}
                        disabled={isDisabled}                        
                        
                    >
                        <MenuItem value={props.selectValue? props.selectValue: ""} selected><em><IntlMessages id="common.selectone" /></em> </MenuItem>
                        {externalData.map((optn, index) => (
                            <MenuItem key={index} value={optn[fieldId]}>
                                {<IntlMessages id={optn[fieldValue]} />}
                            </MenuItem>
                        )
                        )}
                    </TextField>
                </FormControl>
            </Grid>
        </>
    )
}

export function ModalIcons(props) {
    const { name, label, value, options, placeholder, errors, touched, handleChange, ...rest } = props;

    const childToParent = (icon: string) => {
        const element = document.querySelector("#" + name);
        element?.setAttribute("value", icon);
        element?.dispatchEvent(new window.Event('change', { bubbles: true }))
    }


    const Icon = props => {
        const { iconName, size, color } = props;
        const icon = React.createElement(FontAwesome[iconName]);
        return <div style={{ fontSize: size, color: color }}>{icon}</div>;
    }

    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <base.BasicModal childToParent={childToParent} />
                <TextField
                    value={value ?? ""}
                    variant="outlined"
                    name={name}
                    id={name}
                    fullWidth
                    placeholder={placeholder || ''}
                    label={<IntlMessages id={`common.${label?.toLowerCase()}`} />}
                    {...rest}
                    error={!!errors && touched}
                    helperText={errors}
                    onChange={handleChange}
                    style={{ visibility: 'hidden', display: 'none' }}
                />
            </Grid>
        </>
    )
}

export function SubmitButton(props) {
    //const { isSubmitting, name, ...rest } = props;
    const { isSubmitting, name, handleSubmit, ...rest } = props;   
    return (
        <Grid item xs={12} md={12} xl={12}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Button
                    sx={{
                        position: "relative",
                        minWidth: 100,
                    }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}>
                    <IntlMessages id={name?.toLowerCase()} />
                </Button>
                <Button
                    sx={{
                        position: "relative",
                        minWidth: 100,
                        ml: 2.5,
                    }}
                    color="primary"
                    variant="outlined"
                    type="reset"
                    onClick={(event) => handleSubmit(event,"cancel")}
                >
                    <IntlMessages id="common.cancel" />
                </Button>
            </Box>
        </Grid>
    )
}

export function CKEditorEva(props){
    const { name, label, placeholder, value, errors, touched, handleChange, isVisible, ...rest } = props;
    const toolbarConfig = {
        //items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
        items: [
            "selectAll",
            "undo",
            "redo",
            "bold",
            "italic",
            "blockQuote",
            "link",
            //"ckfinder",
            //"uploadImage",
            //"imageUpload",
            "heading",
            //"imageTextAlternative",
            //"toggleImageCaption",
            //"imageStyle:inline",
            //"imageStyle:alignLeft",
            //"imageStyle:alignRight",
            //"imageStyle:alignCenter",
            //"imageStyle:alignBlockLeft",
            //"imageStyle:alignBlockRight",
            //"imageStyle:block",
            //"imageStyle:side",
            //"imageStyle:wrapText",
            //"imageStyle:breakText",
            "indent",
            "outdent",
            "numberedList",
            "bulletedList",
            //"mediaEmbed",
            "insertTable",
            "tableColumn",
            "tableRow",
            "mergeTableCells"
        ]
    };
    
    return (
        <>
            <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
                <CKEditor
                    editor={ClassicEditor}
                    data={value ?? ""}
                    name={name}
                    id={name}
                    onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                    //console.log(Array.from( editor.ui.componentFactory.names() ), 'tati ui editor')
                    editor.editing.view.change((writer) => {                        
                        writer.setStyle(
                        "height",
                        "500px",     
                        editor.editing.view.document.getRoot()
                        );
                    });
                    }}
                    onChange={(event, editor) => {
                    const data = editor.getData();
                    //handleDescriptionChange(data);
                    handleChange(name,data);
                    }}
                    config={{
                        toolbar: toolbarConfig                       
                    }}
                    onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                    }}
/*
                    onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                        "height",
                        "500px",
                        editor.editing.view.document.getRoot()
                        );
                    });
                    }}
*/
                />
            </Grid>
        </>
     )
 
}


export const getFormElem = (param: ElementParameters) => {    
    const props = {
        name: param.elemName,
        label: param.labels[param.elemName],
        value: param.value,
        options: param.elementSchema.options,
        placeholder: param.elementSchema.placeholder,
        errors: param.errors,
        touched: param.touched,
        handleChange: param.handleChange,
        isVisible: param.allStates != undefined ? param.allStates : true,
        fieldId: param.elementSchema.fieldId ? param.elementSchema.fieldId : "",
        externalURL: param.elementSchema.externalURL ? param.elementSchema.externalURL : "",
        fieldValue: param.elementSchema.fieldValue ? param.elementSchema.fieldValue : "",
        xs: param.elementSchema.xs,
        sm: param.elementSchema.sm,
        mask: param.elementSchema.mask,
        key: param.index,
        selectValue : param.elementSchema.selectValue,
        isDisabled: param.isDisabled        
    }
    

    switch (param.elementSchema.type) {
        case 'hidden':
            return (<TextFieldHidden {...props} />)
        case 'text':
        case 'email':
            return (<TextFieldEva {...props} />)
        case 'password':
            return (<TextFieldPassword {...props} />)
        case 'textarea':
            return (<TextFieldAreaEva {...props} />)
        case 'RadioButtons':
            return (<RadioButtons {...props} />)
        case 'password':
            return (<TextFieldPassword {...props} />)
        case 'select':
            return (<SelectFieldEva {...props} />)
        case 'image':
            return (<ImageDropzone {...props} />)
        case 'selectExternal':
            return (<SelectExternal {...props} />)
        case 'icon':
            return (<ModalIcons {...props} />)
        case 'datePicker':
            return (<DtPicker {...props} />) 
        case 'CKEditor':
            return (<CKEditorEva {...props} />)       
        default:
            return (
                <div>You are a User.</div>
            )
    }
}

// export const makeFormElem = (isSubmitting, errors, touched, data, labels, formData, helpers) => {
//     {
//         Object.keys(data).map((key, index) => {
//             const props = {
//                 label: labels[param.elemName],
//                 value: param.value,
//                 options: param.elementSchema.options,
//                 placeholder: param.elementSchema.placeholder,
//                 errors: param.errors,
//                 touched: param.touched,
//                 handleChange: param.handleChange,
//                 isVisible: param.allStates != undefined ? param.allStates : true,
//                 fieldId: param.elementSchema.fieldId ? param.elementSchema.fieldId : "",
//                 externalURL: param.elementSchema.externalURL ? param.elementSchema.externalURL : "",
//                 fieldValue: param.elementSchema.fieldValue ? param.elementSchema.fieldValue : "",
//                 xs: param.elementSchema.xs,
//                 sm: param.elementSchema.sm,
//                 mask: param.elementSchema.mask,
//                 key: param.index
//             }
    
//             return(
//             <>
//                 {getFormElem(labels, key, formData[key], data[key], errors[key], touched[key], data[key].type == "image" ? helpers.HandleImage : helpers.HandleChange, index)}
//             </>
//         )})
//     }
//     <SubmitButton {...{ isSubmitting: isSubmitting, name: 'register' }} />
// }yar

