import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses, GridColumnMenu, GridFilterModel, GridFilterItem } from '@mui/x-data-grid';
import { GridEvents } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { grey } from '@mui/material/colors';
import moment from "moment";
import { Box, alpha, useTheme, Grid, Popover, Typography } from '@mui/material';
import { Tooltip } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as base from "../base";
import { useNavigate } from "react-router-dom";
import { Fonts } from '../../../shared/constants/AppEnums';
import { useIntl } from 'react-intl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import UnpublishedRoundedIcon from '@mui/icons-material/UnpublishedRounded';
import { any } from 'prop-types';
import ReportB2B from 'pages/admin/reportB2B';
import { useSelector,useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { IToolTipeCuston } from '../lists';



interface IFilterModel {
  columnField: string,
  operatorValue: string,
  value: string,
}



interface TableProps {
  rowHead: any[],
  rowData: any[],
  actions: any[],
  processData?: boolean,
  filterModeDatal?: IFilterModel,
  selectFunction?,
  modalFunction?,
  tooltipCustom?:IToolTipeCuston[],
  pageName?:string,
}
export default function DataTable(props: TableProps) {
  const { rowHead, rowData, actions, processData, filterModeDatal, selectFunction, modalFunction, tooltipCustom,pageName } = props;  
  
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(25);
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFunctionClose, setSelectedFunctionClose] = useState<Function | null>(null);
  const [open, setOpen] = useState(false);
  const [idModal, setIdModal] = useState("");
  const { filtro, vision,enabledOnHeader,filterDataTable,filterCustom } = useSelector<AppState, AppState["filter"]>(({ filter }) => filter);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModel(model);
    //dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader:enabledOnHeader, filterDataTable:model,filterCustom:filterCustom});
    dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader:enabledOnHeader, filterDataTable:{pageName:pageName, filter: model},filterCustom:filterCustom});
  };
    
  const navigate = useNavigate();

  const intl = useIntl();
  const theme = useTheme();

  const handleDataLoaded = () => {
    setLoading(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
    setIdModal("");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowId) => {

    setAnchorEl(event.currentTarget);
    setSelectedRow(rowId)
    if (!open) {
      setOpen(!open);
      setIdModal('simple-popover');
    }
  };

  const closeModal = () => {
    setSelectedRow(null);
  };

  const openDialog = (event: React.MouseEvent<HTMLButtonElement>, rowId) => {
    modalFunction(event, rowId)
  }

  const header: GridColDef[] = [];
  const id = { field: 'id', headerName: 'ID', hide: true };
  header.push(id);

  rowHead.forEach(element => {
    const columnsNew: GridColDef = {
      field: element.Field,
      headerName: element.isNotTranslate ? element.id : intl.formatMessage({ id: element.id }),
      headerAlign: element.align ? element.align : "left",
      width: element.minWidth,      
      hide: (!element.show),
      filterable: element.isSearch,
      align: element.align ? element.align : "left",
      sortable: element.isActions ? false : true,
      disableColumnMenu: element.isActions ? true : false,
      renderCell: (paramns) => {
        var tooltipDescription = "";
        if(tooltipCustom != undefined && tooltipCustom?.length>0){
          tooltipDescription = (tooltipCustom.find(x => x.name === paramns.value)?.description ?? "").toString();
        } 
        if (tooltipDescription === "") {
          tooltipDescription =  paramns.value;
        }
        return element.isActions
          ?
          actions.map((act, key) => {
            return act.id === "edit" ?
              (
                <IconButton key={key} aria-label={act.Field} onClick={() => base.ActionsFunction(navigate, act.id, paramns.id)}>
                  <EditIcon />
                </IconButton>
              ) :
              act.id === "info" ?
                (
                  <IconButton key={key} aria-label={act.Field} onClick={() => base.ActionsFunction(navigate, act.id, paramns.id)}>
                    <InfoTwoToneIcon color='primary' fontSize='medium' />
                  </IconButton>
                ) :
                act.id === "modal" ?
                  (
                    <IconButton key={key} aria-label={act.Field} onClick={(event) => handleClick(event, paramns.id)}>
                      <EditIcon />
                      <Popover
                        id={idModal}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        onClick={(event) => event.stopPropagation()}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            borderRadius: 1,
                            overflow: "hidden"
                          }
                        }}
                      ><Box
                          sx={{
                            position: "relative",
                            //mt: "-10px",
                            "&::before": {
                              backgroundColor: "white",
                              content: '""',
                              display: "block",
                              position: "absolute",
                              width: 12,
                              height: 12,
                              top: 104,
                              transform: "rotate(45deg)",
                              left: "calc(10% - 6px)",
                              borderBottom: 1,
                              borderRight: 1,
                              borderColor: "#cccccc"
                            }
                          }}
                        />
                        <ReportB2B reportId={selectedRow} functionClose={handleClose} reloadFunction={selectFunction} />
                      </Popover>
                    </IconButton>


                  ) :
                  act.id === "download" ?
                    (
                      <IconButton key={key} aria-label={act.Field} onClick={() => base.ActionsFunction(navigate, act.id, paramns.id, selectFunction)}>
                        <DownloadRoundedIcon fontSize='medium' />
                      </IconButton>
                    ) :
                    act.id === "dialog" ?
                      (
                        <IconButton key={key} aria-label={act.Field} onClick={(event) => openDialog(event, paramns.id)}>
                          <InfoTwoToneIcon color='primary' fontSize='medium' />
                        </IconButton>
                      ) :
                      (
                        <IconButton
                          key={key}
                          aria-label={act.Field}
                          onClick={() => {
                            setLoading(true);
                            base.ActionsFunction(navigate, act.id, paramns.id, selectFunction)
                          }
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )

          })

          :
          element.format === "money"
            ?
            paramns.value !== 0 ? "R$ " + Intl.NumberFormat('pt-BR').format(Number(paramns.value)) : "-"
            :
            // element.format === "date" && element.isDashboardField ?
            //       paramns.value === "0001-01-01T00:00:00"?  "-" : moment(paramns.value).format("DD/MM/YYYY")
            // :
            element.format === "cpf"
              ?
              paramns.value.length === 14
                ?
                paramns.value = paramns.value.replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, "$1.$2.$3/$4-$5")
                :
                paramns.value = paramns.value.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4")
              :
              element.format === "Boolean"
                ?
                paramns.value === true
                  ?
                  intl.formatMessage({ id: "common.active" })
                  :
                  intl.formatMessage({ id: "common.inactive" })
                :
                element.format === "BooleanIcon"
                  ?
                  paramns.value === true
                    ?
                    <CheckCircleRoundedIcon color='primary' fontSize='medium' />
                    :
                    <UnpublishedRoundedIcon color='disabled' fontSize='medium' />
                  :
                  element.format === "link"
                    ?
                    <IconButton key={key} aria-label={paramns.value} onClick={(event) => openDialog(event, paramns.id)}>
                      {paramns.value}
                    </IconButton>
                    :
                    element.isTranslateValue
                      ?
                      intl.formatMessage({ id: paramns.value })
                      :                      
                      <Tooltip title={tooltipDescription} >
                        <span className="csutable-cell-trucate">{paramns.value}</span>
                      </Tooltip>
                    
      },
    }
    header.push(columnsNew);
  });



  var newData = rowData.map(item => {
    const newItem = {};
    header.forEach(column => {
      const fieldName = column.field;
      newItem[fieldName] = item[fieldName];
    });
    return newItem;
  });

  //const keyItem = rowHead.find(item => item.isKey === true);
  const keyItem = rowHead.find((item: { Field: string; isKey: boolean }) => item.isKey === true);
  const key = keyItem ? keyItem.Field : undefined;


  newData = newData.map((item) => {
    return {
      ...item,
      id: item[key]
    };
  });


  newData = newData.map((item: { [key: string]: any }) => {
    const { [keyItem.Field]: _, ...rest } = item;
    return rest;
  });

  const newHeader = header.filter(item => item.field !== key);

  useEffect(() => {

    if (filterModeDatal !== undefined) {      
      const filterData: GridFilterModel = {
        items: [
          { id: 1, columnField: filterModeDatal.columnField, operatorValue: filterModeDatal.operatorValue, value: filterModeDatal.value },
        ],
      };
      setFilterModel(filterData);
      dispatch({ type: 'ATUALIZAR_FILTRO', filtro: filtro, vision: vision, enabledOnHeader:enabledOnHeader, filterDataTable:{pageName:pageName, filter:filterData},filterCustom:filterCustom});
    }

    if (rowData.length > 0 || processData === true) {
      //handleDataLoaded();
      if(filterDataTable.pageName == pageName){
        if (filterDataTable.filter !== undefined && filterDataTable.filter !== ''){
          setFilterModel(filterDataTable.filter);
        }
      }
      setLoading(false);
    }    
    else {
      setLoading(true);
    }
  
  }, [rowData, processData])

  const localizedTextsMap = {
    //noRowsLabel: 'Sem linhas.',
    noRowsLabel: intl.formatMessage({ id: 'datagrid.noRowsLabel' }),
    noResultsOverlayLabel: intl.formatMessage({ id: 'datagrid.noResultsOverlayLabel' }),
    errorOverlayDefaultLabel: intl.formatMessage({ id: 'datagrid.errorOverlayDefaultLabel' }),
    filterPanelColumns: intl.formatMessage({ id: 'datagrid.filterPanelColumns' }),
    filterPanelOperators: intl.formatMessage({ id: 'datagrid.filterPanelOperators' }),
    filterPanelInputPlaceholder: intl.formatMessage({ id: 'datagrid.filterPanelInputPlaceholder' }),
    columnMenuUnsort: intl.formatMessage({ id: 'datagrid.columnMenuUnsort' }),
    columnMenuSortAsc: intl.formatMessage({ id: 'datagrid.columnMenuSortAsc' }),
    columnMenuSortDesc: intl.formatMessage({ id: 'datagrid.columnMenuSortDesc' }),
    columnMenuFilter: intl.formatMessage({ id: 'datagrid.columnMenuFilter' }),
    columnMenuHideColumn: intl.formatMessage({ id: 'datagrid.columnMenuHideColumn' }),
    columnMenuShowColumns: intl.formatMessage({ id: 'datagrid.columnMenuShowColumns' }),
    filterOperatorContains: intl.formatMessage({ id: 'datagrid.filterOperatorContains' }),
    filterOperatorEquals: intl.formatMessage({ id: 'datagrid.filterOperatorEquals' }),
    filterOperatorStartsWith: intl.formatMessage({ id: 'datagrid.filterOperatorStartsWith' }),
    filterOperatorEndsWith: intl.formatMessage({ id: 'datagrid.filterOperatorEndsWith' }),
    //filterOperatorIs: intl.formatMessage({ id: 'datagrid.filterOperatorIs' }),
    //filterOperatorNot: intl.formatMessage({ id: 'datagrid.filterOperatorNot' }),
    //filterOperatorAfter: intl.formatMessage({ id: 'datagrid.filterOperatorAfter' }),
    //filterOperatorOnOrAfter: intl.formatMessage({ id: 'datagrid.filterOperatorOnOrAfter' }),
    //filterOperatorBefore: intl.formatMessage({ id: 'datagrid.filterOperatorBefore' }),
    //filterOperatorOnOrBefore: intl.formatMessage({ id: 'datagrid.filterOperatorOnOrBefore' }),
    filterOperatorIsEmpty: intl.formatMessage({ id: 'datagrid.filterOperatorIsEmpty' }),
    filterOperatorIsNotEmpty: intl.formatMessage({ id: 'datagrid.filterOperatorIsNotEmpty' }),
    filterOperatorIsAnyOf: intl.formatMessage({ id: 'datagrid.filterOperatorIsAnyOf' }),
    columnsPanelTextFieldLabel: intl.formatMessage({ id: 'datagrid.columnsPanelTextFieldLabel' }),
    columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'datagrid.columnsPanelTextFieldPlaceholder' }),
    columnsPanelDragIconLabel: intl.formatMessage({ id: 'datagrid.columnsPanelDragIconLabel' }),
    columnsPanelShowAllButton: intl.formatMessage({ id: 'datagrid.columnsPanelShowAllButton' }),
    columnsPanelHideAllButton: intl.formatMessage({ id: 'datagrid.columnsPanelHideAllButton' }),
  };


  const columnDate = rowHead.filter(item => item.format === "date");
  newData.map((item) => {
    columnDate.map((column) => {
      var dateFormatted;
      var propertyName = column.Field;
      var itemData = item[propertyName];
      // Extrai o ano e mês e dia da data fornecida
      if (itemData !== undefined && String(itemData) !== "0001-01-01T00:00:00") {
        dateFormatted = moment(String(itemData)).format("DD/MM/YYYY")
      }
      else {
        dateFormatted = "-"
      }
      // const itemNewDate = `${day}/${month}/${year}`;
      item[propertyName] = dateFormatted;
    })
  });

  const columnDateTime = rowHead.filter(item => item.format === "dateTime");

  newData.map((item) => {
    columnDateTime.map((column) => {
      var propertyName = column.Field;
      var itemData = item[propertyName];

      // Extrai o ano e mês e dia da data fornecida
      const [year, month, day] = itemData.toString().substring(0, 10).split('-');
      const [hour, min, sec] = itemData.toString().substring(11, 19).split(':');
      const itemNewDate = `${day}/${month}/${year} ${hour}:${min}:${sec}`;
      item[propertyName] = itemNewDate;
    })
  });


  const columnMonth = rowHead.filter(item => item.format === "monthYear");

  newData.map((item) => {
    columnMonth.map((column) => {
      var propertyName = column.Field;
      var itemData = item[propertyName];

      // Extrai o ano e mês e dia da data fornecida
      const [year, month, day] = itemData.toString().substring(0, 10).split('-');
      const itemNewDate = `${month}/${year}`;
      item[propertyName] = itemNewDate;
    })
  });

  return (
    <Box style={{ height: '63vh', width: '100%' }}>
      <DataGrid
        aria-label="sticky table"
        rows={newData}
        columns={newHeader}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        disableSelectionOnClick
        localeText={localizedTextsMap}
        loading={loading}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        //components={{
        //   Toolbar: customToolbarSupplier,
        // }}
        componentsProps={{
          panel: {
            sx: {
              '& .MuiDataGrid-columnsPanelRow:first-of-type': {
                display: 'none',
              },
            },
          },
        }}
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            width: 8,
            height: 8
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: "primary.main",
            borderRadius: 2,
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
            backgroundColor: "#757575",
            borderRadius: 2
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            border: 'none',
          },
          //'& .MuiDataGrid-cell:hover': {
          //    color: 'primary.main',
          //},
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 700,
            fontfamily: 'Poppins, serif',
            //fontWeight: Fonts.BOLD,
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important",
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },          
        }}

      />
    </Box>
  );
}


