export const configs ={
    Params : {
      pageName : "reportb2b",
      urlFront : "Invoice",
      urlApi:"Invoice",
      methodApi:"getReportB2B",
      ShowAddButton:false,
      ShowUploadButton:false,
      buttonFilter: false,
      isSearch: false,
      isSearchInit : true,
      isNoActive: true,
      submittingName: "Carregar",
      functionModal: "modalReportB2B",
      filterColumns:[
        { id: "file",Field: "Name", type: "String" },     
      ]
      },
    Fields:{
      UploadId: {
        type: "hidden",
        label: "",
        value: "key",
        required: false,
        xs:"12",
        sm:"12",
      },
      
      
    },
    List:{
      filter:{
        Name: {
          type: "text",
          label: "name",
          value: "",
          required: true,
          xs:"12",
          sm:"6",
        }        
      },
      gridColumns: [
        { id: "actions", Field: "Actions", minWidth: 120, show: true, isActions: true, align: "center" , isSearch:false},
        { id: "uploadId", Field: "UploadId", type: "Guid", minWidth: 170, show: false, isKey: true, isSearch: false,isNotTranslate:true }, 
        { id: "referenceMonth", Field: "ReferenceMonth", type: "String",format: "monthYear", minWidth: 150, show: true,align: "center", isSearch: true },
        { id: "name", Field: "Name", minWidth: 400, show: true, isSearch: true },
        { id: "invoiceName", Field: "InvoiceName", type: "String", minWidth: 200, show: true, isSearch: true },
        { id: "invoiceNumber", Field: "InvoiceNumber", type: "String", minWidth: 200, show: true, isSearch: true },
        { id: "billedDate", Field: "BilledDate", type: "String",format: "date", minWidth: 150, show: true, isSearch: true },
        { id: "billingDate", Field: "BillingDate", type: "String",format: "date", minWidth: 150, show: true, isSearch: true },
        { id: "invoiceBilled", Field: "InvoiceBilled",format: "BooleanIcon", minWidth: 120, show: true, align: "center" , isSearch: true },
      ],
      actions: [
       { id: "modal", Field: "edit", icon: "Edit" },
       { id: "delete", Field: "delete", icon: "Delete" },
       { id: "download", Field: "download", icon: "Download" },
      ]
    }
  };
  